












































































import { Component } from "vue-property-decorator"
import serviceList from "@/components/ServiceList.vue"
import ContactTeaser from "@/components/ContactTeaser.vue"
import HeroIntro from "@/components/post/HeroHeader.vue"
import ApiMixin from "@/views/apiMixin"
import { MetaInfo } from "vue-meta"
import Error from "@/components/Error.vue"
import postButton from "@/components/post/Button.vue"
import textLogin from "@/components/post/TextLogin.vue"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules"
import { ValidationRule } from "vee-validate/dist/types/types"
import axios from "axios"
import PostAlert from "@/components/post/Alert.vue"
import { genHead } from "@/helpers"

Component.registerHooks(["metaInfo"])

extend("required", required)
extend("email", email)
extend("endsdeen", {
    validate: (value: string) => {
        return value.toLocaleLowerCase().endsWith(".de") || value.toLocaleLowerCase().endsWith(".com")
    },
    message: "Ihre E-Mail-Adresse muss auf .de oder .com enden",
} as ValidationRule)


Component.registerHooks(["metaInfo"])

@Component({ components: { serviceList, HeroIntro, ContactTeaser, Error, textLogin, postButton, ValidationProvider, ValidationObserver, PostAlert } })
export default class forgotPassword extends ApiMixin {
    $refs!: {validationObserver: HTMLSpanElement}

    metaInfo(): MetaInfo { return genHead.bind(this)() }

    submitAttempt = 0
    email = ""
    error = false

    async forgot(): Promise<void> {
        // @ts-ignore
        const validated = await this.$refs.validationObserver.validate()
        this.submitAttempt++

        if (validated) {
            await axios.get(`${this.$configBackendApi}/forgotpassword/${this.email}`)
                .finally(() => this.$router.replace(`/${this.$i18n.locale}/login/passwordsuccess`))
        } else {
            this.$track({
                event: "error_message",
                type: "form_error",
                // @ts-ignore
                error_message: `${(this.$t("general.inputError") as string).toLocaleLowerCase()}: ${Object.keys(this.$refs.validationObserver.errors as Record<string, string>).join(",")}`,
            })
        }
    }
}
