



















































































import { Component, Vue } from "vue-property-decorator"
import postButton from "@/components/post/Button.vue"

interface TeaserElement {
    icon: string
    header: string
    text: string
    buttonText: string
    buttonUrl: string
    internal?: boolean
}

@Component({ components: { postButton } })
export default class ContactTeaser extends Vue {
    get contacts(): TeaserElement[] {
        const contact =
        {
            icon: "I",
            header: this.$t("contactTeaser.form.header").toString(),
            text: this.$t("contactTeaser.form.text").toString(),
            buttonText: this.$t("contactTeaser.form.button").toString(),
            buttonUrl: `/${this.$i18n.locale}/${this.$i18n.locale === "de" ? "kontakt" : this.$i18n.locale === "it" ? "contatto" : "contact"}`,
            internal: true,
        }

        return this.$i18n.locale === "en" ? [contact] : [contact,
            {
                // eslint-disable-next-line quotes
                icon: '"',
                header: this.$t("contactTeaser.newsletter.header").toString(),
                text: this.$t("contactTeaser.newsletter.text").toString(),
                buttonText: this.$t("contactTeaser.newsletter.button").toString(),
                buttonUrl: ((): string => {
                    switch (this.$i18n.locale) {
                        case "de":
                            return "https://digital-solutions.post.ch/de/digital-health/newsletter-post-digital-health"
                        case "fr":
                            return "https://digital-solutions.post.ch/fr/digital-health/newsletter-post-digital-health"
                        case "it":
                            return "https://digital-solutions.post.ch/it/digital-health/newsletter-post-digital-health"
                        default:
                            return "https://digital-solutions.post.ch/de/digital-health/newsletter-post-digital-health"
                    }
                })()
            }]
    }
}
