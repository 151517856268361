





























































































































import { Component, Watch } from "vue-property-decorator"
import serviceList from "@/components/ServiceList.vue"
import ContactTeaser from "@/components/ContactTeaser.vue"
import HeroIntro from "@/components/post/HeroHeader.vue"
import ApiMixin from "@/views/apiMixin"
import { MetaInfo } from "vue-meta"
import Error from "@/components/Error.vue"
import Input from "@/components/post/Input.vue"
import postButton from "@/components/post/Button.vue"
import textLogin from "@/components/post/TextLogin.vue"
import { User } from "@/api"
import PostAlert from "@/components/post/Alert.vue"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules"
import { ValidationRule } from "vee-validate/dist/types/types"
import Checkbox from "@/components/post/Checkbox.vue"
import axios, { AxiosResponse } from "axios"
import { genHead } from "@/helpers"

Component.registerHooks(["metaInfo"])

extend("required", required)
extend("email", email)
extend("endsdeen", {
    validate: (value: string) => {
        return value.toLocaleLowerCase().endsWith(".de") || value.toLocaleLowerCase().endsWith(".com")
    },
    message: "Ihre E-Mail-Adresse muss auf .de oder .com enden",
} as ValidationRule)

@Component({ components: { serviceList, HeroIntro, ContactTeaser, Error, Input, textLogin, postButton, PostAlert, ValidationProvider, ValidationObserver, Checkbox } })
export default class Login extends ApiMixin {
    $refs!: {validationObserver: HTMLSpanElement}

    metaInfo(): MetaInfo { return genHead.bind(this)() }

    // reload data on language change
    @Watch("$i18n.locale") onLocaleChange(): void {
        this.$fetch()
    }

    email = ""
    password = ""
    error = false
    loading = false
    submitAttempt = 0
    remember = false

    async login(force = false): Promise<void> {
        // @ts-ignore
        let validated = force ? true : await this.$refs.validationObserver.validate()
        this.submitAttempt++
        this.error = false
        this.loading = true

        if (validated) {
            axios.post(`${this.$configBackendApi}/user/login`,
                {
                    email: this.email,
                    password: this.password,
                    remember: this.remember
                },
                { headers: { "Content-Type": "application/json" } }
            )
                .then((res: AxiosResponse<User>) => {
                    this.$store.commit("setUser", res.data)
                    this.$track({ event: "login", method: "sales_login", secondary_segment: res.data.id })
                    this.$router.replace(`/${this.$i18n.locale}/restricted/documents`)
                })
                .catch(() => this.error = true)
                .finally(() => this.loading = false)
        } else {
            this.$track({
                event: "error_message",
                type: "form_error",
                // @ts-ignore
                error_message: `${(this.$t("general.inputError") as string).toLocaleLowerCase()}: ${Object.keys(this.$refs.validationObserver.errors as Record<string, string>).join(",")}`,
            })
        }
    }

    created(): void {
        document.body.classList.add("login")

        if (this.$store.state.user) {
            axios.post(`${this.$configBackendApi}/user/login`, null, { headers: { "Content-Type": "application/json" } })
                .then(() => this.$router.replace(`/${this.$i18n.locale}/restricted/documents`))
        }
    }

    beforeDestroy(): void {
        document.body.classList.remove("login")
    }
}
