/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormUser } from '../models/FormUser';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Create a new user
     * @returns any User successful created
     * @throws ApiError
     */
    public static createUser({
        json,
        request,
    }: {
        json: User,
        request?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/create',
            query: {
                'request': request,
            },
            body: json,
            errors: {
                400: `User with this email already exists`,
                403: `User has no post.ch email`,
                500: `Other error`,
            },
        });
    }

    /**
     * Get a list of all users (admin only)
     * @returns User List of users
     * @throws ApiError
     */
    public static allUsers({
        request,
    }: {
        request?: string,
    }): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/all',
            query: {
                'request': request,
            },
            errors: {
                403: `Auth user has no admin permission`,
                500: `Other error`,
            },
        });
    }

    /**
     * Save user object (admin only)
     * @returns User Successful saved user
     * @throws ApiError
     */
    public static saveUser({
        userId,
        json,
        request,
    }: {
        userId: string,
        json: FormUser,
        request?: string,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user/save/{userId}',
            path: {
                'userId': userId,
            },
            query: {
                'request': request,
            },
            body: json,
            errors: {
                403: `Auth user has no admin permission`,
                404: `User not found in database`,
                409: `Email not allowed`,
                500: `Other error`,
            },
        });
    }

    /**
     * Login a user
     * @returns User User successful logged in
     * @throws ApiError
     */
    public static login({
        json,
        request,
    }: {
        json: User,
        request?: string,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/login',
            query: {
                'request': request,
            },
            body: json,
            errors: {
                401: `Auth-cookie/header not valid or expired`,
                403: `User state does not allow a login. Response: 'unconfirmed' = user can be offered to resend confirmation link, 'disabled' = user needs to be enabled by admin, empty string = user is locked for whatever reason`,
                404: `User does not exist or password wrong (we don't reveal the exact reason in the request)`,
                424: `Could not get Gentics JWT token`,
                500: `Other error`,
            },
        });
    }

}
