







































// https://www.experience-hub.ch/document/2385#/komponenten-1/buttons

import {Component, Vue, Prop} from "vue-property-decorator"

@Component
export default class PostButton extends Vue {
    @Prop({default: "regular", required: false}) size!: "small" | "regular" | "large" | "huge"
    @Prop({default: false, required: false}) icon!: boolean
    @Prop({default: false, required: false}) text!: boolean
    @Prop({default: false, required: false}) nav!: boolean
    @Prop({default: false, required: false}) outlined!: boolean
    @Prop({default: false, required: false}) reverseHover!: boolean
    @Prop({default: false, required: false, type: Boolean}) disabled!: boolean
}
