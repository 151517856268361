var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.register()}}},[_c('container',{staticClass:"my-big column"},[_c('validation-observer',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('row',{staticClass:"w-100"},[_c('column',[_c('h2',{staticStyle:{"margin-bottom":"16px"}},[_vm._v(_vm._s(_vm.$t("login.salesregister")))]),_c('p',{staticClass:"color-black-60 font-weight-light"},[_vm._v(_vm._s(_vm.$t("login.registertext")))])])],1),_c('row',{staticClass:"mt-big"},[_c('column',[_c('validation-provider',{attrs:{"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('p',{staticClass:"mb-small"},[_vm._v(_vm._s(_vm.$t("login.title")))]),_vm._l((_vm.$t('contact.form.genderOptions')),function(genderOption){return _c('div',{key:genderOption.toString(),staticClass:"mb-small d-flex align-items-center"},[_c('post-radio',{attrs:{"name":"gender","form-value":genderOption,"valid":!errors.length,"autocomplete":"sex"},on:{"change":function($event){return validate(_vm.gender)}},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('p',[_vm._v(_vm._s(genderOption))])])],1)})]}}],null,true)})],1)],1),_c('row',{staticClass:"justify-content-start mobile-margin-25"},[_c('column',[_c('validation-provider',{attrs:{"mode":"lazy","vid":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('label',{staticClass:"mt-large"},[_vm._v(_vm._s(_vm.$t("contact.form.firstname"))+"*")]),_c('text-login',{staticClass:"w-100 w-lg-60",attrs:{"valid":validated ? !errors.length : null,"autocomplete":"given-name"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})]}}],null,true)}),_c('validation-provider',{attrs:{"mode":"lazy","vid":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('label',{staticClass:"mt-large"},[_vm._v(_vm._s(_vm.$t("contact.form.lastname"))+"*")]),_c('text-login',{staticClass:"w-100 w-lg-60",attrs:{"valid":validated ? !errors.length : null,"autocomplete":"family-name"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})]}}],null,true)}),_c('label',{staticClass:"mt-large"},[_vm._v(_vm._s(_vm.$t("login.workerfunction"))+"*")]),_c('validation-provider',{attrs:{"mode":"lazy","vid":"occupation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('text-login',{staticClass:"w-100 w-lg-60",attrs:{"valid":validated ? !errors.length : null},model:{value:(_vm.occupation),callback:function ($$v) {_vm.occupation=$$v},expression:"occupation"}})]}}],null,true)}),_c('validation-provider',{attrs:{"mode":"lazy","vid":"employeeNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('label',{staticClass:"mt-large"},[_vm._v(_vm._s(_vm.$t("login.workernumber"))+"*")]),_c('text-login',{staticClass:"w-100 w-lg-60",attrs:{"valid":validated ? !errors.length : null},model:{value:(_vm.employeeNumber),callback:function ($$v) {_vm.employeeNumber=$$v},expression:"employeeNumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"mode":"lazy","vid":"email","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('label',{staticClass:"mt-large"},[_vm._v(_vm._s(_vm.$t("contact.form.email"))+"*")]),_c('text-login',{staticClass:"w-100 w-lg-60",attrs:{"valid":validated ? !errors.length : null,"type":"email","autocomplete":"username"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"mode":"lazy","vid":"password","rules":{ required: true, password: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('label',{staticClass:"mt-large"},[_vm._v(_vm._s(_vm.$t("login.passwordlabel"))+"*")]),_c('text-login',{staticClass:"w-100 w-lg-60",attrs:{"valid":validated ? !errors.length : null,"type":"password","autocomplete":"new-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('p',{staticClass:"font-weight-light my-small text-mini"},[_vm._v(_vm._s(_vm.$t("login.passrequire")))])]}}],null,true)}),_c('validation-provider',{attrs:{"mode":"lazy","vid":"passwordRepeat","rules":{ required: true, passwordSame: { existingPassword: _vm.password } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('label',{staticStyle:{"margin-top":"12px"}},[_vm._v(_vm._s(_vm.$t('login.repeat'))+"*")]),_c('text-login',{staticClass:"w-100 w-lg-60",attrs:{"valid":validated ? !errors.length : null,"type":"password","autocomplete":"new-password"},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}})]}}],null,true)})],1),_c('transition',{attrs:{"name":"expand-y"}},[(invalid && _vm.submitAttempt)?_c('column',{staticClass:"mt-regular",attrs:{"col":"12"}},[_c('post-alert',{staticClass:"color-white background-color-error",scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('icon',[_vm._v("M")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("general.formError"))+" ")])],1):_vm._e()],1),_c('transition',{attrs:{"name":"expand-y"}},[(_vm.error)?_c('column',{staticClass:"py-regular",attrs:{"col":"12"}},[_c('post-alert',{staticClass:"color-white background-color-error mt-regular",scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('icon',[_vm._v("M")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("general.error"))+" ")])],1):_vm._e()],1)],1),_c('row',{staticClass:"w-100 mt-regular"},[_c('column',{staticClass:"grow"}),_c('column',{staticClass:"shrink"},[_c('router-link',{attrs:{"to":("/" + (_vm.$i18n.locale) + "/login")}},[_c('post-button',{attrs:{"size":"large","text":"","type":"reset"}},[_vm._v(_vm._s(_vm.$t("general.cancel")))])],1)],1),_c('column',{staticClass:"shrink",staticStyle:{"min-width":"200px"}},[_c('post-button',{staticClass:"background-color-black color-white",attrs:{"size":"large","type":"submit","disabled":_vm.loading}},[_vm._v(_vm._s(_vm.$t('login.registerbutton')))])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }