import Vue from "vue"
import VueI18n, {LocaleMessageArray, LocaleMessageObject, LocaleMessages} from "vue-i18n"
import {specialCharacters} from "@/helpers"

Vue.use(VueI18n)


function replaceSpecial(messages: LocaleMessageObject | LocaleMessages | LocaleMessageArray): LocaleMessageObject | LocaleMessages | LocaleMessageArray {
    const replacedMessage: LocaleMessageObject | LocaleMessages = {}
    const specialCharactersPair = Object.entries(specialCharacters)

    if (Array.isArray(messages)) {
        return messages.map(message => replaceSpecial(message))
    } else if (typeof messages === "object") {
        for (const key in messages) {
            if (Object.prototype.hasOwnProperty.call(messages, key)) {
                // @ts-ignore
                const message: LocaleMessageObject | LocaleMessages | LocaleMessageArray = messages[key] as LocaleMessageObject | LocaleMessages
                if (typeof message === "object") {
                    replacedMessage[key] = replaceSpecial(message)
                } else {
                    // @ts-ignore
                    let newMessage = message.toString()
                    specialCharactersPair.forEach(pair => {
                        newMessage = newMessage.replaceAll(pair[0], pair[1])
                    })
                    replacedMessage[key] = newMessage
                }
            }
        }
    } else if (typeof messages === "string") {
        specialCharactersPair.forEach(pair => messages = (messages as string).replaceAll(pair[0], pair[1]))
        return messages
    }
    return replacedMessage
}

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages: LocaleMessages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    // @ts-ignore
    return replaceSpecial(messages)
}

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "de",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
    messages: loadLocaleMessages()
})

export default i18n