



























import { Component, Prop } from "vue-property-decorator"
import ValueMixin from "@/components/valueMixin"

@Component
export default class Radio extends ValueMixin {
    // @ts-ignore
    val!: string
    @Prop({ required: false, type: String, default: "" }) name!: string
    @Prop({ required: false, type: String, default: "" }) formValue!: string
    @Prop({ default: false, required: false }) noBorder!: boolean
    @Prop({ default: null, required: false }) valid!: boolean

    hovered = false
}
