import {cleanFields, replaceSpecial} from "@/helpers"
import {binaryData, button, image, rawAd} from "@/types/meshInterfaces"

export default class {
    title!: string
    uuid!: string
    created!: Date
    content!: string
    image!: image
    button!: button
    localImage!: binaryData
    isPublished!: boolean

    constructor(rawData: rawAd) {
        // @ts-ignore
        const cleanData = cleanFields(rawData)

        for (const key in cleanData) {
            if (Object.prototype.hasOwnProperty.call(cleanData, key)) {
                if (key === "created") {
                    // @ts-ignore
                    this.created = new Date(cleanData.created)
                } else {
                    // @ts-ignore
                    this[key] = replaceSpecial(cleanData[key])
                }
            }
        }
    }
}
