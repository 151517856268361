var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header"},[_c('div',{class:[
            'nav',
            {
                detached: _vm.navDetached,
                ani: _vm.navAnimate,
            } ],style:(_vm.navDetached ? 'transform: translateY(-' + _vm.detachedTop + 'px);' : '')},[_c('div',{ref:"mainNavEl",staticClass:"ppm-main-navigation__wrapper"},[(_vm.langNavActive)?_c('div',{staticClass:"ppm-main-navigation__click-overlay",on:{"click":_vm.hideClickOverlay}}):_vm._e(),_c('div',{ref:"metaNavEl",staticClass:"ppm-main-navigation__meta-nav-wrapper"},[_c('div',{staticClass:"ppm-main-navigation__meta-nav-container"},[(!_vm.$route.path.includes('/restricted'))?_c('button',{class:[
                            'ppm-main-navigation__langnav-item',
                            'ppm-main-navigation__langnav-item--button',
                            { active: _vm.langNavActive } ],attrs:{"aria-expanded":"false"},on:{"click":function($event){return _vm.toogleLangNav()}}},[_c('span',{staticClass:"visuallyhidden"},[_vm._v("Sprachnavigation")]),_c('abbr',{staticClass:"ppm-main-navigation__langnav-abbreviation",attrs:{"title":(_vm.langs.find(function (el) { return el.short == _vm.$i18n.locale; }) || { name: '' }).name,"lang":_vm.$i18n.locale}},[_vm._v(_vm._s(_vm.$i18n.locale))]),_c('icon',{staticClass:"ppm-main-navigation__svgicon ppm-main-navigation__svgicon--2113_angle-small-down"},[_vm._v("α")]),_c('span',{staticClass:"visuallyhidden"},[_vm._v(_vm._s((_vm.langs.find(function (el) { return el.short == _vm.$i18n.locale; }) || { name: "" }).name))])],1):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(_vm.langNavActive)?_c('div',{staticClass:"ppm-main-navigation__nav-lang-wrapper"},[_c('nav',{staticClass:"ppm-main-navigation__langnav",attrs:{"aria-labelledby":"langnav-label_2470"}},[_c('h2',{staticClass:"visuallyhidden",attrs:{"id":"langnav-label_2470","aria-level":"2"}},[_vm._v("Sprachnavigation")]),_c('ul',{staticClass:"ppm-main-navigation__langnav-list",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.langs),function(l){return _c('li',{key:l.short,class:[
                                            'ppm-main-navigation__langnav-item',
                                            {
                                                'ppm-main-navigation__langnav-item--active':
                                                    l.short == _vm.$i18n.locale,
                                            } ],staticStyle:{"cursor":"pointer"},attrs:{"aria-hidden":l.short == _vm.$i18n.locale}},[_c('a',{staticClass:"ppm-main-navigation__langnav-link",on:{"click":function($event){return _vm.setLang(l.short)}}},[_c('abbr',{staticClass:"ppm-main-navigation__langnav-abbreviation",attrs:{"title":l.name,"tabindex":l.short == _vm.$i18n.locale
                                                        ? '-1'
                                                        : false,"lang":l.short}},[_vm._v(_vm._s(l.short))]),_c('span',{staticClass:"visuallyhidden"},[_vm._v(_vm._s(l.name))])])])}),0)])]):_vm._e()])],1)]),_c('div',{staticClass:"ppm-main-navigation__main-nav-wrapper"},[_c('div',{staticClass:"ppm-main-navigation__main-nav-container"},[_c('div',{staticClass:"ppm-main-navigation__logo"},[_c('router-link',{attrs:{"to":_vm.$store.state.user ? ("/" + (_vm.$i18n.locale) + "/restricted/documents") : ("/" + (_vm.$i18n.locale))}},[_c('img',{staticClass:"ppm-main-navigation__logo-image",attrs:{"id":"post-logo","width":"208.28","height":"72","src":_vm.$t('header.logo'),"alt":_vm.$t('header.logodesc')}})])],1),(_vm.$store.state.user && _vm.$store.state.user.role === 'ADMIN' && _vm.$mq !== 'xs')?_c('div',{staticClass:"ppm-main-navigation__login"},[_c('div',{staticClass:"klp-widget-anonymous"},[_c('div',{staticClass:"klp-widget-anonymous__wrapper"},[_c('router-link',{attrs:{"to":("/" + (_vm.$i18n.locale) + "/admin")}},[_c('span',{staticClass:"klp-widget-anonymous__text",staticStyle:{"padding-right":"0"}},[_vm._v("Administration")])])],1)])]):_vm._e(),_c('div',{staticClass:"ppm-main-navigation__login"},[_c('div',{staticClass:"klp-widget-anonymous"},[_c('div',{staticClass:"klp-widget-anonymous__wrapper"},[(_vm.$store.state.user)?_c('button',{on:{"click":function($event){return _vm.logout()}}},[_c('span',{staticClass:"klp-widget-anonymous__text"},[_vm._v("Logout")]),_c('icon',{staticClass:"ppm-svg-icon"},[_vm._v("Â")])],1):_c('router-link',{attrs:{"to":("/" + (_vm.$i18n.locale) + "/login")}},[_c('icon',{staticClass:"ppm-svg-icon"},[_vm._v("Â")])],1)],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }