var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact pb-giant",attrs:{"role":"main"}},[_c('container',[_c('row',[_c('column',{staticClass:"py-large",attrs:{"col":"12"}},[_c('post-breadcrumbs')],1),_c('column',{attrs:{"col":"12","col-xl":"8"}},[_c('h2',{staticStyle:{"margin-bottom":"8px"}},[_vm._v(_vm._s(_vm.$t("contact.header.title")))]),_c('h2',{staticClass:"font-weight-light",staticStyle:{"margin-bottom":"16px"}},[_vm._v(_vm._s(_vm.$t("contact.header.subtitle")))]),_c('p',[_vm._v(_vm._s(_vm.$t("contact.header.content")))])])],1)],1),_c('container',{staticClass:"mb-biggerbig"},[_c('row',{staticClass:"no-gutters",staticStyle:{"place-content":"center"}},[_c('column',{attrs:{"col":"12","col-xl":"8"}},[_c('validation-observer',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.checkValidation()}}},[_c('row',[_c('column',{staticClass:"pt-large",attrs:{"col":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('contact.form.yourRequest')))]),_c('post-line')],1),_c('column',{attrs:{"col":"12"}},[_c('h6',{staticClass:"text-fixed-small mb-small"},[_vm._v(_vm._s(_vm.$t('contact.form.productQuestions')))])]),_c('column',{attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"mode":"lazy","rules":"required","vid":"product","detectInput":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
var validated = ref.validated;
return [_c('post-select',{attrs:{"vid":"product","value":_vm.product.id,"name":"product","label":_vm.$t('contact.form.product'),"valid":validated ? !errors.length : null},scopedSlots:_vm._u([{key:"menu",fn:function(ref){
var close = ref.close;
return [_c('row',{staticClass:"no-gutters",attrs:{"aria":"","role":"list"}},_vm._l((_vm.orderedServices),function(orderedService){return _c('column',{key:orderedService.category,attrs:{"col":"12","role":"listitem"}},[_c('h6',{staticClass:"text-fixed-small my-small",class:("color-dark-" + (orderedService.color)),staticStyle:{"margin-left":"16px","margin-right":"16px"}},[_vm._v(_vm._s(orderedService.category))]),_c('post-list',_vm._l((orderedService.services),function(service){return _c('div',{key:service.id,staticClass:"text-regular",class:_vm.product.id === service.id ? 'background-color-warmgray' : '',on:{"click":function($event){[_vm.product = service, validate(service), close()]}}},[_vm._v(_vm._s(service.name))])}),0)],1)}),1)]}}],null,true)},[_c('transition',{attrs:{"name":"fade"}},[(_vm.product.id)?_c('span',{staticStyle:{"width":"calc(100% - 30px)","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[_c('span',{key:_vm.product.id,staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.product.name))])])],1):_vm._e()])],1)]}}],null,true)})],1),_c('column',{staticClass:"mt-regular",attrs:{"col":"12"}},[_c('validation-provider',{ref:"textareaProvider",attrs:{"mode":"lazy","vid":"request","detectInput":false,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var validated = ref.validated;
return [_c('post-input',{attrs:{"value":_vm.request,"label":_vm.$t('contact.form.yourRequest'),"valid":validated ? !errors.length : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focused = ref.focused;
var blured = ref.blured;
var id = ref.id;
return [_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.request),expression:"request"}],ref:"requestTextarea",attrs:{"name":"request","id":id,"type":"text"},domProps:{"value":(_vm.request)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.request=$event.target.value},function($event){[validate($event), _vm.handleTextarea()]}],"focus":function($event){return focused()},"blur":function($event){return blured()}}},'textarea',_vm.$attrs,false))]}}],null,true)})]}}],null,true)})],1),_c('column',{staticClass:"pt-large",attrs:{"col":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('contact.form.yourData')))]),_c('post-line')],1),_c('column',{staticClass:"mt-regular shrink",staticStyle:{"flex-direction":"row"},attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"mode":"lazy","vid":"gender","rules":"required","detectInput":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('fieldset',{staticClass:"layout row px-none shrink",staticStyle:{"align-self":"flex-start","flex-wrap":"nowrap"}},_vm._l((_vm.$t('contact.form.genderOptions')),function(genderOption,i){return _c('post-radio',{key:genderOption,staticClass:"layout column col shrink",class:{ 'ml-small': i },attrs:{"name":"gender","formValue":genderOption,"valid":!errors.length,"autocomplete":"sex"},on:{"change":function($event){[validate(genderOption), _vm.gender = genderOption.toString()]}}})}),1),_c('div',{staticClass:"layout row px-none grow"})]}}],null,true)})],1),_c('column',{staticClass:"mt-regular",attrs:{"col":"6"}},[_c('validation-provider',{attrs:{"mode":"lazy","vid":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('post-input',{attrs:{"name":"firstName","label":_vm.$t('contact.form.firstname'),"valid":validated ? !errors.length : null,"autocomplete":"given-name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)})],1),_c('column',{staticClass:"mt-regular",attrs:{"col":"6"}},[_c('validation-provider',{attrs:{"mode":"lazy","vid":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('post-input',{attrs:{"name":"lastName","label":_vm.$t('contact.form.lastname'),"valid":validated ? !errors.length : null,"autocomplete":"family-name"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)})],1),_c('column',{staticClass:"mt-regular",attrs:{"col":"12"}},[_c('post-input',{attrs:{"name":"company","label":((_vm.$t('contact.form.company')) + " (" + (_vm.$t('contact.form.optional')) + ")"),"autocomplete":"organization"},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('column',{staticClass:"mt-regular",attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"mode":"lazy","vid":"email","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('post-input',{attrs:{"name":"eMail","label":_vm.$t('contact.form.email'),"valid":_vm.emailTouched || validated ? !errors.length : null,"autocomplete":"email"},on:{"blur":function($event){_vm.emailTouched = true}},model:{value:(_vm.eMail),callback:function ($$v) {_vm.eMail=$$v},expression:"eMail"}})]}}],null,true)})],1),_c('column',{staticClass:"mt-regular",attrs:{"col":"12"}},[_c('post-input',{attrs:{"name":"tel","label":((_vm.$t('contact.form.phone')) + " (" + (_vm.$t('contact.form.optional')) + ")"),"autocomplete":"tel"},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}}),_c('h6',{staticClass:"text-fixed-small",staticStyle:{"margin-top":"4px"}},[_vm._v(_vm._s(_vm.$t('contact.form.phoneMessage')))])],1),_c('transition',{attrs:{"name":"expand-y"}},[(invalid && _vm.submitAttempt)?_c('column',{staticClass:"mt-regular",attrs:{"col":"12"}},[_c('post-alert',{staticClass:"color-white background-color-error",scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('icon',[_vm._v("M")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("general.formError"))+" ")])],1):_vm._e()],1),_c('column',{attrs:{"col":"12"}},[_c('post-line')],1),_c('column',{attrs:{"col":"12"}},[_c('row',{staticClass:"px-none"},[(_vm.$mq !== 'xs' && _vm.$mq !== 'sm')?_c('column',{staticClass:"grow"}):_vm._e(),_c('column',{class:_vm.$mq === 'xs' || _vm.$mq === 'sm' ? 'col-6' : 'shrink'},[_c('router-link',{attrs:{"to":("/" + (_vm.$i18n.locale))}},[_c('post-button',{staticStyle:{"min-width":"140px"},attrs:{"size":"large","outlined":""}},[_vm._v(_vm._s(_vm.$t('general.cancel')))])],1)],1),_c('column',{class:_vm.$mq === 'xs' || _vm.$mq === 'sm' ? 'col-6' : 'shrink'},[_c('post-button',{staticClass:"background-color-black color-white",staticStyle:{"min-width":"140px"},attrs:{"type":"submit","size":"large","disabled":_vm.loading}},[_vm._v(_vm._s(_vm.$t('general.send')))])],1)],1)],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }