


import { MetaInfo } from "vue-meta/types/vue-meta"
import { Component, Vue } from "vue-property-decorator"

@Component
export default class PageNotFound extends Vue {
        metaInfo(): MetaInfo {
        return {
            meta: [
                { name: "robots", content: "noindex" },
            ],
        }
    }
    
     mounted(): void {
        this.$track({
            event: "error_message",
            type: "page_not_found",
            error_message: "page not found",
            error_code: "404",
        })
        this.$router.replace("/")
    }
}
