


























































































































import { Component, Watch } from "vue-property-decorator"
import serviceList from "@/components/ServiceList.vue"
import ContactTeaser from "@/components/ContactTeaser.vue"
import HeroIntro from "@/components/post/HeroHeader.vue"
import ApiMixin from "@/views/apiMixin"
import { MetaInfo } from "vue-meta"
import Error from "@/components/Error.vue"
import textLogin from "@/components/post/TextLogin.vue"
import postButton from "@/components/post/Button.vue"
import PostAlert from "@/components/post/Alert.vue"
import axios from "axios"

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules"
import { ValidationRule } from "vee-validate/dist/types/types"
import { genHead } from "@/helpers"

Component.registerHooks(["metaInfo"])

extend("required", required)
extend("password", {
    validate: (value: string) => {
        const minTwelve = /.{12,}/.test(value)
        const other = [/[A-Z]/.test(value), /[a-z]/.test(value), /\d/.test(value), /[^\w\s\d]/.test(value)]
        console.info(other)
        return minTwelve && other.filter(o => o).length >= 3
    },
    message: "Ihr Passwort entspricht nicht den Bedingungen",
} as ValidationRule)
extend("passwordSame", {
    params: ["existingPassword"],
    validate: (value: string, { existingPassword }: { existingPassword: string }) => value === existingPassword,
    message: "Passwort nicht korrekt wiederholt.",
} as ValidationRule)

@Component({ components: { serviceList, HeroIntro, ContactTeaser, Error, textLogin, postButton, ValidationProvider, ValidationObserver, PostAlert } })
export default class newPassword extends ApiMixin {
    $refs!: {validationObserver: HTMLSpanElement}

    metaInfo(): MetaInfo { return genHead.bind(this)() }

    password = ""
    passwordRepeat = ""
    submitAttempt = 0
    loading = false
    error = false
    tokenValidityError = false

    // reload data on language change
    @Watch("$i18n.locale") onLocaleChange(): void {
        this.$fetch()
    }

    async checkTokenValidity(): Promise<void> {
        try {
            await axios.get(`${this.$configBackendApi}/user/checkpwtoken/${this.$route.params.token}`)
            this.tokenValidityError = false
        } catch {
            this.tokenValidityError = true
            this.$track({
                event: "error_message",
                type: "token_expired",
            })
        }
    }

    mounted(): void {
        this.checkTokenValidity()
    }

    async reset(): Promise<void> {
        // @ts-ignore
        const validated = await this.$refs.validationObserver.validate()
        this.submitAttempt++
        this.error = false

        await this.checkTokenValidity()

        if (validated && !this.tokenValidityError) {
            await axios.put(`${this.$configBackendApi}/user/resetpassword/${this.$route.params.token}`, { password: this.password })
                .then(() => this.$router.replace(`/${this.$i18n.locale}/login`))
                .catch(() => this.error = true)
        } else {
            this.$track({
                event: "error_message",
                type: "form_error",
                // @ts-ignore
                error_message: `${(this.$t("general.inputError") as string).toLocaleLowerCase()}: ${Object.keys(this.$refs.validationObserver.errors as Record<string, string>).join(",")}`,
            })
        }
    }
}
