














import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class PostCard extends Vue {
    @Prop({default: false, required: false}) shadow!: boolean
}
