































import { Component, Vue } from "vue-property-decorator"
import postDialog from "@/components/post/Dialog.vue"
import postButton from "@/components/post/Button.vue"

@Component({ components: { postDialog, postButton } })
export default class LoggedInReminder extends Vue {
    isOpen = true
}
