/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FormUser = {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    lang: string;
    occupation: string;
    employeeNumber: string;
    role: FormUser.role;
    isEnabled: boolean;
    isLocked: boolean;
};

export namespace FormUser {

    export enum role {
        ADMIN = 'ADMIN',
        USER = 'USER',
        NONE = 'NONE',
    }


}

