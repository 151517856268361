




















import {Component, Vue} from "vue-property-decorator"
import {RouteRecord} from "vue-router"

@Component
export default class PostBreadcrumbs extends Vue {
    linkTo(route: RouteRecord): string {
        const paramRegex = new RegExp("^:(.+)$")
        return route.path
            .split("/")
            .map(pathElement =>
                paramRegex.test(pathElement)
                    ? pathElement.replace(
                    paramRegex,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    this.$route.params[pathElement.match(paramRegex)![1]]
                    )
                    : pathElement
            )
            .join("/")
    }
}
