// @ts-nocheck
// enthält viele Funktionen zum transformieren von Daten, daher viele unknowns.

import { MetaInfo } from "vue-meta"
import { Route } from "vue-router"

export function cleanFields(obj: Record<string, unknown>): Record<string, unknown> {
    function flattenProperty(obj: Record<string, unknown>, propToFlatten: string): unknown {
        for (const prop in obj) {
            if (prop === propToFlatten) {
                obj = { ...obj, ...obj[prop] }
                delete obj[prop]

                obj = flattenProperty(obj, propToFlatten)
            } else if (typeof obj[prop] === "object")
                obj[prop] = flattenProperty(obj[prop], propToFlatten)
        }
        return obj
    }

    return flattenProperty(obj, "fields")
}

export function timeoutPromise(ms: number): Promise<void> {
    return new Promise((resolve) => { setTimeout(() => { resolve() }, ms) })
}

export const specialCharacters = {
    "{br}": "\u00AD",
    "{nbsp}": "\u00A0",
    "{zwsp}": "\u200B",
}

export function replaceSpecial(
    message: Record<string, unknown> | string | number | Array<unknown>
): Record<string, unknown> | string | number | Array<unknown> {
    if (Array.isArray(message)) {
        return message.map(e => replaceSpecial(e))
    } else if (typeof message === "object" && message) {
        const newMessage: Record<string, unknown> = {}
        for (const key in message) {
            if (Object.prototype.hasOwnProperty.call(message, key)) {
                newMessage[key] = replaceSpecial(message[key])
            }
        }
        return newMessage
    } else if (typeof message === "string") {
        const specialCharactersPair = Object.entries(specialCharacters)
        let newMessage = message
        specialCharactersPair.forEach(pair => {
            newMessage = newMessage.replaceAll(pair[0], pair[1])
        })
        return newMessage
    } else {
        return message
    }
}

export function removeSpecial(message: string): string {
    const specialValues = Object.values(specialCharacters)
    specialValues.forEach(special => {
        message = message.replaceAll(special, "")
    })
    return message
}

export const colorMap: { [key: string]: string } = {
    "Integration Service": "olive",

    // de
    "E-Patientenservices": "aubergine",
    "E-Patientenservice": "aubergine",
    Basisservice: "petrol",
    "E-Kollaborationsservices": "purpur",
    "E-Kollaborationsservice": "purpur",

    // en
    "E-patient services": "aubergine",
    "E-patient service": "aubergine",
    "Core Service": "petrol",
    "E-collaboration service": "purpur",
    "E-collaboration services": "purpur",

    // fr
    "Services électroniques pour les patients": "aubergine",
    "Service électroniques pour les patients": "aubergine",
    "Service électronique pour les patients": "aubergine",
    "Core Services": "petrol",
    "Services électroniques de collaboration": "purpur",
    "Service électroniques de collaboration": "purpur",
    "Service électronique de collaboration": "purpur",

    // it
    "Servizi elettronici per il paziente": "aubergine",
    "Servizi elettronici per il pazienti": "aubergine",
    "Servizi elettronico per il pazienti": "aubergine",
    "Servizio elettronici per il paziente": "aubergine",
    "Servizio elettronici per il pazienti": "aubergine",
    "Servizio elettronico per il pazienti": "aubergine",
    "Servizo elettronico per il pazienti": "aubergine",
    "Servizio elettronico per i pazienti": "aubergine",
    "Coreservice": "petrol",
    "Servizi elettronici di collaborazione": "purpur",
    "Servizio elettronici di collaborazione": "purpur",
    "Servizio elettronico di collaborazione": "purpur",
}

export const fileIconMap: { [key: string]: string } = {
    "application/pdf": "ψ",
    "application/msword": "τ",
    "application/vnd.openxmlformats-officedocument. wordprocessingml.document": "τ",
    "application/msexcel": "υ",
    "application/vnd.openxmlformats-officedocument. spreadsheetml.sheet": "υ",
    "application/mspowerpoint": "φ",
    "application/rss+xml": "²",
    "application/gzip": "Ã",
    "application/zip": "Ã",
    "application/x-gtar": "Ã",
    "application/x-shar": "Ã",
    "application/x-tar": "Ã",
    "application/x-ustar": "Ã",
    "text/calendar": "Œ",
    "text/html": "ë",
    "audio/*": "*",
    "text/*": "x",
    "image/*": "ú",
    "drawing/*": "ú",
    "video/*": "³",
    "*": "Æ"
}

export function getFileIcon(mimeType: string): string {
    return Object.entries(fileIconMap).find(iconMap => (new RegExp(iconMap[0].replace("*", ".*"))).test(mimeType))?.[1] || "Æ"
}

/* make sure you call this function using genHead.bind(this)(args) */
export function genHead(metaTitle?: string = null, metaDescription?: string = null, noLinks = false): MetaInfo {
    const
        link = [
            { rel: "canonical", href: `https://cuore.post.ch${this.$route.path}` },
            { rel: "alternate", hreflang: "de", href: `https://cuore.post.ch${this.$route.path.replace(`/${this.$i18n.locale}`, "/de")}` },
            { rel: "alternate", hreflang: "fr", href: `https://cuore.post.ch${this.$route.path.replace(`/${this.$i18n.locale}`, "/fr")}` },
            { rel: "alternate", hreflang: "it", href: `https://cuore.post.ch${this.$route.path.replace(`/${this.$i18n.locale}`, "/it")}` },
            { rel: "alternate", hreflang: "en", href: `https://cuore.post.ch${this.$route.path.replace(`/${this.$i18n.locale}`, "/en")}` },
        ],
        htmlAttrs = { lang: this.$i18n.locale },
        meta = (this.$route as Route).meta.noIndex || (this.$route as Route).meta.requiresLogin ? [{robots: "noindex"}] : []

    metaDescription && meta.push({ description: metaDescription })

    return {
        title: metaTitle || this.$t(`meta.title.${this.$route.name}`) as string || this.$t("meta.title.app") as string || undefined,
        link: noLinks ? undefined : link,
        meta: meta,
        htmlAttrs: htmlAttrs
    }
}
