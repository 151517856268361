




























import {Component, Prop} from "vue-property-decorator"
import card from "@/components/post/Card.vue"
import checkboxGroup from "@/components/post/CheckboxGroup.vue"
import radioGroup from "@/components/post/RadioGroup.vue"
import postSelect from "@/components/post/Select.vue"
import valueMixin from "@/components/valueMixin"

@Component({components: {card, checkboxGroup, radioGroup, postSelect}})
export default class FilterMenu extends valueMixin {
    // @ts-ignore
    val!: string[] | string

    @Prop({default: () => [], required: true}) options!: string[]
    @Prop({default: "", required: false}) label!: string
    @Prop({default: false, required: false}) multiple!: boolean
}
