
















import {Component, Prop} from "vue-property-decorator"
import radio from "@/components/post/Radio.vue"
import list from "@/components/post/List.vue"
import valueMixin from "@/components/valueMixin"

@Component({components: {radio, list}})
export default class RadioGroup extends valueMixin {
    // @ts-ignore
    val!: string

    @Prop() options!: string[]
    @Prop({default: false, required: false}) noBorder!: boolean
    @Prop({default: false, required: false}) required!: boolean
}
