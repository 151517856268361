










































































import { Component } from "vue-property-decorator"
import serviceList from "@/components/ServiceList.vue"
import ContactTeaser from "@/components/ContactTeaser.vue"
import HeroIntro from "@/components/post/HeroHeader.vue"
import ApiMixin from "@/views/apiMixin"
import { MetaInfo } from "vue-meta"
import Error from "@/components/Error.vue"
import textLogin from "@/components/post/TextLogin.vue"
import postButton from "@/components/post/Button.vue"
import postRadio from "@/components/post/Radio.vue"
import { User } from "@/api"
import axios, { AxiosResponse } from "axios"
import { genHead } from "@/helpers"
Component.registerHooks(["metaInfo"])

@Component({ components: { serviceList, HeroIntro, ContactTeaser, Error, textLogin, postButton, postRadio } })
export default class AdminPanel extends ApiMixin {
    metaInfo(): MetaInfo { return genHead.bind(this)() }


    data: User | null = null
    status = ""

    async fetch(): Promise<void> {
        await axios.get(
            `${this.$configBackendApi}/user/get/${this.$route.params.id}`,
            // { headers: { "X-AUTH-TOKEN": this.$cookies.get("auth.token") } }
        )
            .then(res => {
                const data = (res as AxiosResponse<User>)
                this.data = data.data
                this.status = data.data.isEnabled ? "enabled" : data.data.isEnabled === false ? "locked" : ""
            })
    }

    async save(): Promise<void> {
        await axios.put(
            `${this.$configBackendApi}/user/save/${this.$route.params.id}`,
            { ...this.data, isEnabled: this.status === "enabled" ? true : this.status === "locked" ? false : null } as User,
            // { headers: { "X-AUTH-TOKEN": this.$cookies.get("auth.token") } }
        )
            .then(() => this.$router.push(`/${this.$i18n.locale}/admin`))
    }

    async remove(): Promise<void> {
        await axios.delete(`${this.$configBackendApi}/user/delete/${this.$route.params.id}`)
            .then(() => this.$router.push(`/${this.$i18n.locale}/admin`))
    }
}
