export interface DataLayerObject {
    /** 
        static, required 
        Event-Bezeichnung (statisch pro dataLayer.push)
    */
    "event": string
    /**
        recommended
        Einzigartige Kennung der Seite aus dem CMS; e.g. technische ID oder eindeutige Bezeichnung der Seite
    */
    "page_id"?: string
    /**
        required
        Sprachunabhängiger Seitenname - auf Basis DE
    */
    "page_name_language_neutral": string
    /**
        required
        Sprachunabhängige URL - auf Basis DE
    */
    "page_url_language_neutral": string
    /**
        recommended
        Seitentyp
    */
    "page_type"?: "landing_page" | "category" | "content" | "checkout"
    /**
        required
        Organisationseinheit (Kennung)
    */
    "department_name": string
    /**
        recommended
        Organisationseinheit (ID), insb. für PostWeb interessant kann aber auf allen Bereich gesetzt werden.
    */
    "department_id"?: string
    /**
        required
        Übergreifender Geschäftsbereich (Group Unit, Function Unit, Subsidiary), cf. Org Chart
    */
    "business_unit": string
    /**
        required
        Unterscheidung zwischen Aurichtung auf Privatkunden, Geschäftskunden, oder beiden Gruppen (allgemein): "pk", "gk", "allgemein"
    */
    "primary_segment": "pk" | "gk" | "allgemein"
    /**
        recommended
        Weitere Unterschiedung von Nutzer:innen- und Kundensegmenten
    */
    "secondary_segment"?: string
    /**
        recommended
        Content-Kategorisierung Level 1 (e.g. Produktgruppe, Breadcrums, o.ä.)
    */
    "page_category1"?: string
    /**
        recommended
        Content-Kategorisierung Level 2 (e.g. Produktgruppe, Breadcrums, o.ä.)
    */
    "page_category2"?: string
    /**
        recommended
        Content-Kategorisierung Level 3 (e.g. Produktgruppe, Breadcrums, o.ä.)
    */
    "page_category3"?: string
    /**
        recommended
        Content-Kategorisierung Level 4 (e.g. Produktgruppe, Breadcrums, o.ä.)
    */
    "page_category4"?: string
    /**
        required, default "national"
        Regionale Content-Ausrichtung der Seite. Dies ist insb. für PostAuto interessant, kann aber für alle Bereiche genutzt werden.
    */
    "content_geo_region": string | "national"
    /**
        required
        Sprachliche Ausrichtung des Contents - selected_language bzw. content_language NICHT Browsersprache; nur Sprache ("en", "it", "de", "fr"; ISO_639-1) nicht Sprach-LandKombination ("de_CH")
    */
    "content_language": "en" | "it" | "de" | "fr"
    /**
        required for search results
        Anzahl Suchresultate
    */
    "search_results"?: string
    /**
        required for search results
        Suchbegriff - nach Korrektur
    */
    "search_term"?: string
    /**
        recommended
        Originaler, ursprünglicher Suchbegriff - vor Korrektur für die Suche/Suchergebnisse
    */
    "original_search_term"?: string
    /**
        recommended
        Versions/Revisions-Kennung für die Seite
    */
    "source_code_version"?: string
    /**
        required, provided
        Plattformkennung, wird vergeben pro Seite (siehe Dokumentation).
    */
    "platform_name": string
    /**
        required
        Kürzel für aktuelle Umgebung ("prod", "int", "dev")
    */
    "environment": "prod" | "int" | "dev"
    /**
        required
        Unterscheidung zwischen internem und externem Traffic. "true", "false"
    */
    "internal": "true" | "false"
    /**
        required
        Login Status des Users. "true", "false"
    */
    "login_status": "true" | "false"
    /**
        required for SPA transitions
        Indikator (boolean) ob Clientsid Routing/Transition/virtueller Seitenaufruf erfolgt ist.
    */
    "virtual_pageview"?: "true" | "false"
    /**
        required for SPA transitions
        URL nach Routing/Transition
    */
    "virtual_url"?: string,
    /**
       required for forms
       Form Label
   */
    "label"?: string,
}

export const getDefaultValues: () => DataLayerObject = () => ({
    event: "page_context",
    page_name_language_neutral: window.pageName,
    page_url_language_neutral: document.location.href.replace("/en/", "/de/").replace("/it/", "/de/").replace("/fr/", "/de/"),
    page_type: "content",
    department_name: "K34",
    business_unit: "K",
    primary_segment: "gk",
    content_geo_region: "national",
    content_language: document.location.pathname.split("/")[1] as "de" | "it" | "fr" | "en",
    platform_name: "ms_cuore",
    environment: process.env.NODE_ENV === "production" ? document.location.href.includes("post.ch") ? "prod" : "int" : "dev",
    internal: "false",
    login_status: window.location.pathname.includes("restricted") ? "true" : "false",
    virtual_pageview: "false",
    virtual_url: window.location.href
})