






































































































































































import { Component, Watch } from "vue-property-decorator"
import serviceList from "@/components/ServiceList.vue"
import ContactTeaser from "@/components/ContactTeaser.vue"
import HeroIntro, { SrcSet } from "@/components/post/HeroHeader.vue"
import FileList from "@/components/post/FileList.vue"
import ApiMixin from "@/views/apiMixin"
import Service from "@/types/service"
import Fuse from "fuse.js"
import { MetaInfo } from "vue-meta"
import { AxiosResponse } from "axios"
import { DownloadList, DownloadListFile, rawDownloadListResponse, serviceResponse } from "@/types/meshInterfaces"
import Error from "@/components/Error.vue"
import postDialog from "@/components/post/Dialog.vue"
import LoggedInReminder from "@/components/LoggedInReminder.vue"
import { genHead } from "@/helpers"

Component.registerHooks(["metaInfo"])

@Component({ components: { serviceList, HeroIntro, ContactTeaser, Error, FileList, postDialog, LoggedInReminder } })
export default class Documents extends ApiMixin {
    items: Service[] = []
    filteredItems: Service[] = []
    downloadLists: DownloadList[] = []
    openedDowloadList = -1
    search = ""
    fuse: Fuse<Service> | null = null
    loading = true
    filterApplied = false

    metaInfo(): MetaInfo { return genHead.bind(this)() }


    // reload data on language change
    @Watch("$i18n.locale") onLocaleChange(): void {
        this.$fetch()
    }

    // search and sort data locally
    noOfSearches = 0
    filterItems(noTrack = false): void {
        if (this.$route.query.q !== this.search) {
            this.$router.push({ query: { ...this.$route.query, q: this.search } })
        }

        // perform fuzzy search on data
        this.filteredItems = (this.search
            ? this.fuse?.search(this.search).map((i) => i.item) || []
            : this.items
        )
        this.filterApplied = !!this.search

        this.noOfSearches > 1 && !noTrack && this.items.length !== 0 && this.$track({
            event: "search",
            search_results: this.filteredItems.length,
            // TODO: define format
            search_term: this.$route.query || "",
        })

        this.noOfSearches++
    }

    async fetch(): Promise<void> {
        this.loading = true
        await Promise.all([
            this.graphql(
                `{
                    node(uuid: "114c8fe880664f328312996a86d5521a", lang: "${this.$i18n.locale}") {
                        children(filter: {schema: {is: healthService}}) {
                            elements {
                                isPublished
                                ... on healthService { fields {
                                    id
                                    description
                                    tags
                                    area
                                    benefit
                                    category
                                    name
                                    focus
                                    label
                                    filesPublic { ... on downloadFiles { fields { name file { uuid created edited displayName }}}}
                                    filesPrivate { ... on downloadFiles { fields { name file { uuid created edited displayName }}}}
                                    namedUrl
                                }}
                            }
                        }
                    }
                }`,
                true,
            )
                .then(res => (res as AxiosResponse<serviceResponse>).data.data.node.children.elements.filter(el => el.isPublished))
                .then(res => res.map((element) => new Service(element)))
                .then(res => {
                    this.items = res || []

                    this.fuse = new Fuse(this.items, {
                        includeScore: true,
                        keys: [
                            "id",
                            "description",
                            "tags",
                            "area",
                            "benefit",
                            "category",
                            "name",
                            "focus",
                        ],
                        threshold: 0.3,
                    })
                    this.filterItems(true)
                }),
            this.graphql(
                `{
                    node(uuid: "7f9374fdeb53473ba44290e9fff19242", lang: "${this.$i18n.locale}") {
                          children(filter: {schema: {is: downloadList}}) {
                            elements {
                                ... on downloadList {
                                    created
                                    edited
                                    isPublished
                                    fields {
                                        title
                                        description
                                        files {
                                            ... on downloadFiles {
                                                fields {
                                                    name
                                                    file {
                                                        created
                                                        edited
                                                        uuid
                                                        displayName
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`,
                true,
            ).then(res => {
                const downloadLists = (res as AxiosResponse<rawDownloadListResponse>).data.data.node.children.elements.filter(list => list.isPublished)
                if (downloadLists.length === 3) {
                    this.downloadLists = [downloadLists[1], downloadLists[0], downloadLists[2]]
                } else {
                    this.downloadLists = downloadLists
                }

                // this.downloadLists = this.downloadLists.filter(list => list.fields.files.length)
            })
        ])

        this.search = this.$route.query.q as string || ""

        if (this.items.length === 0) {
            this.fetchState.error = "true"
            this.$track({
                event: "error_message",
                type: "nodes_not_found",
                error_message: "The elements were not found in the CMS",
                error_code: "404",
            })
        }

        this.loading = false
    }

    hero = {
        srcSets: [
            { src: require("@/assets/images/KeyVisual_Cuore.webp"), minWidth: 2000, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore2000.webp"), minWidth: 1750, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore1750.webp"), minWidth: 1250, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore1250.webp"), minWidth: 1000, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore1000.webp"), minWidth: 750, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore750.webp"), minWidth: 500, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore500.webp"), minWidth: 300, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore300.webp"), minWidth: 0, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore.jpg"), minWidth: 2000, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore.png"), minWidth: 2000, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore2000.jpg"), minWidth: 1750, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore2000.png"), minWidth: 1750, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore1750.jpg"), minWidth: 1250, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore1750.png"), minWidth: 1250, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore1250.jpg"), minWidth: 1000, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore1250.png"), minWidth: 1000, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore1000.jpg"), minWidth: 750, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore1000.png"), minWidth: 750, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore750.jpg"), minWidth: 500, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore750.png"), minWidth: 500, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore500.jpg"), minWidth: 300, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore500.png"), minWidth: 300, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore300.jpg"), minWidth: 0, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore300.png"), minWidth: 0, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore.webp"), type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore.jpg"), type: "image/jpg" },
        ] as SrcSet[],
        fallback: { src: require("@/assets/images/KeyVisual_Cuore.png"), type: "image/png" } as SrcSet
    }

    hasNewFiles(files: DownloadListFile[] | null): boolean {
        let returnVal = false
        if (files && files.length) {
            const lastSeen = (new Date(this.$store.state.user?.lastLogin || 0)).getTime()
            const newestDate = Math.max(...files.filter(file => file?.fields?.file?.created).map(file => new Date(file.fields.file.created).getTime()))

            returnVal = newestDate > lastSeen
        }
        return returnVal
    }
}
