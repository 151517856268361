












































































































// https://www.experience-hub.ch/document/2385#/komponenten-1/listen

import { Component, Prop } from "vue-property-decorator"
import { downloadFile, rawFileResponse } from "@/types/meshInterfaces"
import List from "@/components/post/List.vue"
import { getFileIcon } from "@/helpers"
import ApiMixin from "@/views/apiMixin"
import { AxiosResponse } from "axios"

interface GenticsFile {
    "path": string
    "displayName": string
    "edited": string
    "fields": {
        "binary": {
            "binaryUuid": string
            "fileName": string
            "fileSize": number
            "mimeType": string
        }
    }
}

interface langVariants<T> {
    de: null | T
    fr: null | T
    it: null | T
    en: null | T
    name: string
    uuid: string
}

@Component({ components: { List } })
export default class PostFileList extends ApiMixin {
    @Prop({ default: () => [], required: true }) value!: downloadFile[]

    files: langVariants<GenticsFile>[] = []

    getIcon(file: GenticsFile | null): string {
        return getFileIcon(file?.fields.binary.mimeType || "")
    }

    getDirectUrl(file: langVariants<GenticsFile>, locale: string): string {
        // @ts-ignore
        return `${this.$configGenticsApi}/digitalhealth/nodes/${file.uuid}/binary/binary?lang=${locale}`
    }

    mounted(): void {
        this.getFiles()
    }

    async getFiles(): Promise<void> {
        const ids = this.value.filter(file => file).map(file => file.file.uuid)
        this.files = await Promise.all(
            this.value.filter((file, i) => file?.file && !ids.includes(file.file.uuid, i + 1)).map(async file => {
                const result: langVariants<GenticsFile> = { de: null, fr: null, it: null, en: null, name: file.name || "", uuid: file.file.uuid }
                const getFile = (lang: string): Promise<null | GenticsFile> => {
                    return this.graphql(`{
                            node(uuid: "${file.file.uuid}", lang: "${lang}") {
                                isPublished
                                path
                                displayName
                                edited
                                ... on binary_content {
                                    uuid
                                    fields {
                                        binary {
                                            binaryUuid
                                            fileName
                                            fileSize
                                            mimeType
                                        }
                                    }
                                }
                            }
                        }`, true)
                        .then(res => (res as AxiosResponse<rawFileResponse>).data.data.node.path && (res as AxiosResponse<rawFileResponse>).data.data.node.isPublished ? (res as AxiosResponse<rawFileResponse>).data.data.node : null)
                }
                await Promise.all([
                    getFile("de").then(res => (result.de = res)),
                    getFile("fr").then(res => (result.fr = res)),
                    getFile("it").then(res => (result.it = res)),
                    getFile("en").then(res => (result.en = res)),
                ])
                return result
            })
        )
    }

    downloadAll(lang: "de" | "fr" | "it" | "en"): string {
        const targetFiles = this.files.filter(file => file[lang])
        return `${this.$configBackendApi}/zipped?ids=${targetFiles.map(file => file.uuid).join("&ids=")}&langs=${targetFiles.map(() => lang).join("&langs=")}`
    }
}
