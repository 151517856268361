






















import { Component, Watch } from "vue-property-decorator"
import serviceList from "@/components/ServiceList.vue"
import ContactTeaser from "@/components/ContactTeaser.vue"
import HeroIntro from "@/components/post/HeroHeader.vue"
import ApiMixin from "@/views/apiMixin"
import { MetaInfo } from "vue-meta"
import Error from "@/components/Error.vue"
import Input from "@/components/post/Input.vue"
import postButton from "@/components/post/Button.vue"
import { genHead } from "@/helpers"


Component.registerHooks(["metaInfo"])

@Component({ components: { serviceList, HeroIntro, ContactTeaser, Error, Input, postButton } })
export default class contactError extends ApiMixin {
    metaInfo(): MetaInfo { return genHead.bind(this)() }


    // reload data on language change
    @Watch("$i18n.locale") onLocaleChange(): void {
        this.$fetch()
    }
}
