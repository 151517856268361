import { User } from "@/api"
import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null as null | User
    },
    mutations: {
        setUser(state, val: User | null) {
            state.user = val
            Vue.$cookies.set("auth.token", val?.authToken || "")
            Vue.$cookies.set("mesh.token", val?.genticsToken || "")
        },
    },
})
