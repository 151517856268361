

















































import { Component, Watch } from "vue-property-decorator"
import serviceList from "@/components/ServiceList.vue"
import ContactTeaser from "@/components/ContactTeaser.vue"
import HeroIntro, { SrcSet } from "@/components/post/HeroHeader.vue"
import ApiMixin from "@/views/apiMixin"
import Service from "@/types/service"
import Ad from "@/types/ad"
import Fuse from "fuse.js"
import { MetaInfo } from "vue-meta"
import { AxiosResponse } from "axios"
import { adResponse, serviceResponse } from "@/types/meshInterfaces"
import Error from "@/components/Error.vue"
import { genHead } from "@/helpers"

Component.registerHooks(["metaInfo"])

@Component({ components: { serviceList, HeroIntro, ContactTeaser, Error } })
export default class Home extends ApiMixin {
    items: Service[] = []
    filteredItems: Service[] = []
    ads: Ad[] = []
    search = ""
    fuse: Fuse<Service> | null = null
    filterApplied = false
    loading = true

    metaInfo(): MetaInfo { return genHead.bind(this)() }

    filters = {
        category: {
            name: this.$t("filter.category") as string,
            options: [] as string[],
            selected: [] as string[],
        },
        // heißt tags, ist aber focus
        tags: {
            name: this.$t("filter.focus") as string,
            options: [] as string[],
            selected: [] as string[],
        },
    }

    resetFilters(): void {
        this.filters.category.selected = []
        this.filters.tags.selected = []
        this.search = ""
        this.filterApplied = false
        this.filterItems()
    }

    // reload data on language change
    @Watch("$i18n.locale") onLocaleChange(): void {
        this.$fetch()
    }

    sort = {
        name: this.$t("filter.sortby") as string,
        options: [this.$t("filter.new"), this.$t("filter.service"), this.$t("filter.category")],
        selected: this.$t("filter.new"),
    }

    // search and sort data locally
    noOfSearches = 0
    filterItems(noTrack = false): void {
        const queryObject = new Map()

        this.search && queryObject.set("q", this.search)
        this.filters.category.selected.length && queryObject.set("category", this.filters.category.selected.join(","))
        this.filters.tags.selected.length && queryObject.set("tags", this.filters.tags.selected.join(","))
        this.sort.selected && queryObject.set("sort", this.sort.selected)

        this.$router.push({ query: {...this.$route.query, ...Object.fromEntries(queryObject)} })

        this.filterApplied = !!(this.search || this.filters.category.selected.length || this.filters.tags.selected.length)

        // perform fuzzy search on data
        this.filteredItems = (this.search
            ? this.fuse?.search(this.search).map((i) => i.item) || []
            : this.items
        )
            .filter((service: Service) => {
                let isIncluded = false
                if (!this.filters.category.selected.length && !this.filters.tags.selected.length) {
                    isIncluded = true
                }

                if (this.filters.category.selected.length) {
                    if (
                        this.filters.category.selected.find(
                            (filterItem) => service.category.includes(filterItem)
                        )
                    ) {
                        isIncluded = true
                    }
                }
                if (this.filters.tags.selected.length) {
                    if (
                        this.filters.tags.selected.find(
                            (filterItem) => service.focus?.includes(filterItem)
                        )
                    ) {
                        isIncluded = true
                    }
                }
                return isIncluded
            })
            .sort((a: Service, b: Service) => {
                if (this.sort.selected === this.$t("filter.service")) {
                    return a.name.localeCompare(b.name)
                } else if (this.sort.selected === this.$t("filter.new")) {
                    return b.created.getTime() - a.created.getTime()
                } else if (this.sort.selected === this.$t("filter.category")) {
                    return a.category.localeCompare(b.category)
                } else {
                    return b.created.getTime() - a.created.getTime()
                }
            })

        const search_term = `${(queryObject.get("q") || "")}\\${(queryObject.get("category") || "")}\\${(queryObject.get("tags") || "")}\\sort:${(queryObject.get("sort") || "")}`
        this.noOfSearches > 1 && !noTrack && this.items.length !== 0 && this.$track({
            event: "search",
            search_results: this.filteredItems.length,
            // TODO: define format
            search_term,
        })

        this.noOfSearches++
    }

    openInNewTab(url: string): void {
        window.open(url, "_blank")
    }

    async fetch(): Promise<void> {
        this.loading = true

        this.filters = {
            category: {
                name: this.$t("filter.category") as string,
                options: [] as string[],
                selected: [] as string[],
            },
            // heißt tags, ist aber focus
            tags: {
                name: this.$t("filter.focus") as string,
                options: [] as string[],
                selected: [] as string[],
            },
        }

        this.sort = {
            name: this.$t("filter.sortby") as string,
            options: [this.$t("filter.new"), this.$t("filter.service"), this.$t("filter.category")],
            selected: this.$t("filter.new"),
        }

        await Promise.all([
            this.graphql(
                `{
                    node(uuid: "114c8fe880664f328312996a86d5521a", lang: "${this.$i18n.locale}") {
                        children(filter: {schema: {is: healthService}}) {
                            elements {
                                uuid
                                isPublished
                                ... on healthService {
                                    created
                                    fields {
                                    id
                                    description
                                    tags
                                    area
                                    benefit
                                    category
                                    name
                                    focus
                                    label
                                    namedUrl
                            }}}}
                        }
                }`
            )
                .then(res => (res as AxiosResponse<serviceResponse>).data.data.node.children.elements.filter(el => el.isPublished))
                .then(res => res.map((element) => new Service(element)))
                .then(res => {
                    this.items = res || []

                    this.filters.category.options = [
                        ...new Set(
                            this.items
                                .filter(service => service.category)
                                .flatMap(service => service.category)
                                .flat()
                        ),
                    ].filter(el => el).sort((a, b) => a.toLocaleLowerCase().trim().localeCompare(b.toLocaleLowerCase().trim()))

                    this.filters.tags.options = [
                        ...new Set(
                            this.items
                                .flatMap(service => service.focus || [])
                        ),
                    ].filter(el => el).sort((a, b) => a.toLocaleLowerCase().trim().localeCompare(b.toLocaleLowerCase().trim()))

                    this.fuse = new Fuse(this.items, {
                        includeScore: true,
                        keys: [
                            "id",
                            "description",
                            "tags",
                            "area",
                            "benefit",
                            "category",
                            "name",
                            "focus",
                            // "label",
                        ],
                        threshold: 0.3,
                    })
                    this.filterItems(true)
                }),
            this.graphql(
                `{
                    node(uuid: "114c8fe880664f328312996a86d5521a", lang: "${this.$i18n.locale}") {
                        children(filter: {schema: {is: ad}}) {
                            elements { ... on ad {
                              uuid
                              created
                              isPublished
                              fields {
                                title
                                content
                                localImage{ ... on BinaryField { binaryUuid }}
                                button { ... on button { fields { text url }}}
                            }}}
                        }
                    }
                }`
            )
                .then((res) =>
                    (res as AxiosResponse<adResponse>).data.data.node.children.elements
                        .map(element => new Ad(element))
                        .filter(element => element.isPublished)
                        .sort(
                            (a: Ad, b: Ad) =>
                                b.created.getTime() - a.created.getTime()
                        )
                )
                .then(res => (this.ads = res)),
        ])

        this.search = this.$route.query.q as string || ""
        this.filters.category.selected = this.$route.query.category ? (this.$route.query.category as string).split(",") : [],
            this.filters.tags.selected = this.$route.query.tags ? (this.$route.query.tags as string).split(",") : []

        if (this.items.length === 0) {
            this.fetchState.error = "true"
            this.$track({
                event: "error_message",
                type: "nodes_not_found",
                error_message: "The elements were not found in the CMS",
                error_code: "404",
            })
        }

        this.loading = false
    }

    hero = {
        srcSets: [
            { src: require("@/assets/images/KeyVisual_Cuore.webp"), minWidth: 2000, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore2000.webp"), minWidth: 1750, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore1750.webp"), minWidth: 1250, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore1250.webp"), minWidth: 1000, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore1000.webp"), minWidth: 750, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore750.webp"), minWidth: 500, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore500.webp"), minWidth: 300, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore300.webp"), minWidth: 0, type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore.jpg"), minWidth: 2000, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore.png"), minWidth: 2000, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore2000.jpg"), minWidth: 1750, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore2000.png"), minWidth: 1750, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore1750.jpg"), minWidth: 1250, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore1750.png"), minWidth: 1250, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore1250.jpg"), minWidth: 1000, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore1250.png"), minWidth: 1000, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore1000.jpg"), minWidth: 750, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore1000.png"), minWidth: 750, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore750.jpg"), minWidth: 500, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore750.png"), minWidth: 500, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore500.jpg"), minWidth: 300, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore500.png"), minWidth: 300, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore300.jpg"), minWidth: 0, type: "image/jpg" },
            { src: require("@/assets/images/KeyVisual_Cuore300.png"), minWidth: 0, type: "image/png" },
            { src: require("@/assets/images/KeyVisual_Cuore.webp"), type: "image/webp" },
            { src: require("@/assets/images/KeyVisual_Cuore.jpg"), type: "image/jpg" },
        ] as SrcSet[],
        fallback: { src: require("@/assets/images/KeyVisual_Cuore.png"), type: "image/png" } as SrcSet
    }
}
