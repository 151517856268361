





















import { Component, Vue, Prop } from "vue-property-decorator"
import serviceCard from "@/components/ServiceCard.vue"
import ServiceClass from "@/types/service"
import { colorMap } from "@/helpers"

@Component({
    components: {
        serviceCard,
    },
})
export default class Service extends Vue {
    @Prop({ required: true }) value!: ServiceClass

    current = new Date()
    color = ""
    colorMap = colorMap

    created(): void {
        const noSpace = this.value.category.trim().replaceAll("\u00AD", "").replaceAll("\u00AD", " ").trim()
        const withSpace = this.value.category.trim().replaceAll("\u00AD", " ")
        this.color = this.colorMap[noSpace] || this.colorMap[withSpace] || "nightblue"
    }
}
