






import {Component, Vue} from "vue-property-decorator"

@Component
export default class Column extends Vue {
    // generates col* classes based on attributes
    // usage: <column col="2" col-sm="4" col-lg="8">
    get classes(): string[] {
        return Object.entries(this.$attrs).map(attr =>
            attr.filter(v => v).join("-")
        )
    }
}
