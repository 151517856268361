import "@babel/polyfill"
import "mutationobserver-shim"
import Vue from "vue"
import VueMeta from "vue-meta"
import App from "@/App.vue"
import router from "@/router"
import i18n from "@/i18n"
// no type declaration available
// @ts-ignore
import VueMq from "vue-mq"
import col from "@/components/layout/Column.vue"
import row from "@/components/layout/Row.vue"
import container from "@/components/layout/Container.vue"
import icon from "@/components/post/Icon.vue"
import store from "@/store"
import VueCookies from "vue-cookies"
import { DataLayerObject, getDefaultValues } from "@/track"
import axios, { AxiosError, AxiosResponse } from "axios"
import { OpenAPI } from "@/api"

window.dataLayer = window.dataLayer || []

Vue.config.productionTip = false

// application level config
Vue.prototype.$configGenticsApi = "https://cuore.post.ch/api/v2"
Vue.prototype.$configBackendApi = OpenAPI.BASE
Vue.prototype.$track = (dataLayerObject: Partial<DataLayerObject>) => {
    const eventData = { ...getDefaultValues(), ...dataLayerObject }
    const _hsq = window._hsq = window._hsq || []
    window.dataLayer.push(eventData)
    _hsq.push(["trackCustomBehavioralEvent", { name: eventData.event, properties: eventData }])
}
Vue.use(VueMeta)
Vue.use(VueCookies)
Vue.use(VueMq, {
    breakpoints: {
        xs: 399,
        sm: 599,
        rg: 779,
        md: 1023,
        lg: 1279,
        xl: Infinity,
    }
})
Vue.component("row", row)
Vue.component("column", col)
Vue.component("container", container)
Vue.component("icon", icon)

axios.interceptors.response.use(
    (response: AxiosResponse<unknown>) => response,
    (error: AxiosError<unknown>) => {
        window.dataLayer.push({
            ...getDefaultValues(),
            ...{
                event: "error_message",
                type: error.name,
                error_message: error.message,
                error_code: error.response?.status,
            }
        })
        return Promise.reject(error)
    })

export const app = new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount("#app")

require("@/styles/main.scss")

