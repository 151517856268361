/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ObjectId } from './ObjectId';

export type User = {
    id: ObjectId;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    lang: string;
    occupation: string;
    employeeNumber: string;
    role: User.role;
    created: string;
    lastLogin: string;
    isConfirmed: boolean;
    isEnabled: boolean;
    isLocked: boolean;
    genticsToken: string;
    genticsTokenExpiration: string;
    authToken: string;
};

export namespace User {

    export enum role {
        ADMIN = 'ADMIN',
        USER = 'USER',
        NONE = 'NONE',
    }


}

