



























































































































































































import axios from "axios"
import { Component, Vue, Watch } from "vue-property-decorator"

@Component
export default class Header extends Vue {
    $refs!: {mainNavEl: HTMLDivElement, metaNavEl: HTMLDivElement}

    lastScrollPos = 0
    navDetached = false
    navAnimate = false
    detachedTop = 0
    navUp = false
    navHeight = 0
    navMetaHeight = 48
    langNavActive = false
    langs = [
        {
            short: "de",
            name: "Deutsch",
        },
        {
            short: "fr",
            name: "Français",
        },
        {
            short: "it",
            name: "Italiano",
        },
        {
            short: "en",
            name: "English",
        },
    ]
    $mq!: string

    handleNavHeight(): void {
        // the height of nav meta + main for current bp
        this.navHeight = this.$refs.mainNavEl.clientHeight
        this.navMetaHeight = this.$refs.metaNavEl.clientHeight
    }

    handleNavPosition(): void {
        // console.log("handleNavPosition: ", window.scrollY);
        const curPos = window.scrollY
        if (this.lastScrollPos < curPos) {
            // DOWN
            // console.log("scrolling down - " + this.navDetached);
            if (this.navDetached && curPos < this.navHeight) {
                // console.debug("case 1");
                this.navUp = true
                this.detachedTop = this.navHeight
            } else if (curPos < this.navHeight) {
                // scrolling down with nav attached, no need to change anything
                // console.debug("case 2");
                this.navDetached = false
            } else if (!this.navDetached) {
                // nav goes above the fold, detach it in position it ready for slide-in
                // console.debug("case 3");
                this.navDetached = true
                this.navAnimate = false
                this.detachedTop = this.navHeight
            } else if (this.navDetached) {
                // slide-out detached nav
                // console.debug("case 4");
                this.navAnimate = true
                this.detachedTop = this.navHeight
            }
        } else {
            // UP
            // console.log("scrolling up - " + this.navDetached);
            if (curPos <= this.navMetaHeight) {
                // attach to normal layout
                // console.debug("case 10");
                this.navDetached = false
                this.navAnimate = false
            } else {
                if (this.navDetached) {
                    // slide in detached nav
                    // console.debug("case 11");
                    this.navAnimate = true
                    this.detachedTop = this.navMetaHeight
                }
            }
        }
        this.lastScrollPos = curPos
    }

    toogleLangNav(): void {
        this.langNavActive = !this.langNavActive
    }

    hideClickOverlay(): void {
        this.langNavActive = false
    }

    mounted(): void {
        window.addEventListener("resize", this.handleNavPosition, { passive: true })
        window.addEventListener("scroll", this.handleNavPosition, { passive: true })
        window.addEventListener("resize", this.handleNavHeight, { passive: true })
        this.handleNavHeight()
    }

    beforeDestroy(): void {
        window.removeEventListener("resize", this.handleNavPosition)
        window.removeEventListener("scroll", this.handleNavPosition)
        window.removeEventListener("resize", this.handleNavHeight)
    }

    @Watch("$route.params.lang") langChanged(): void {
        this.langNavActive = false
    }

    setLang(lang: string): void {
        localStorage.setItem("lang", lang)
        this.$i18n.locale = lang
        this.$router.push(this.$route.fullPath.replace(`/${this.$route.params.lang}`, `/${lang}`))
        this.toogleLangNav()
    }

    logout(): void {
        this.$cookies.remove("auth.token")
        this.$cookies.remove("mesh.token")
        this.$store.commit("setUser", null)
        axios.post(`${this.$configBackendApi}/user/logout`, null)
            .then(() => this.$router.push(`/${this.$i18n.locale}`))
    }
}
