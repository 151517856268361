var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.reset()}}},[_c('container',{staticClass:"my-big column"},[_c('row',[_c('column',[_c('h2',{staticStyle:{"margin-bottom":"16px"}},[_vm._v(_vm._s(_vm.$t("login.newpassword")))]),_c('p',{staticClass:"color-black-60 font-weight-light"},[_vm._v(_vm._s(_vm.$t("login.newpasswordtext")))])])],1),_c('transition',{attrs:{"name":"expand-y"}},[(_vm.tokenValidityError)?_c('row',{staticClass:"mt-big"},[_c('column',[_c('router-link',{staticClass:"no-underline",attrs:{"to":("/" + (_vm.$i18n.locale) + "/login/password")}},[_c('post-alert',{staticClass:"color-white background-color-error",scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('icon',[_vm._v("M")])]},proxy:true}],null,false,972524754)},[_vm._v(" "+_vm._s(_vm.$t("login.newpasswordTokenError"))+" ")])],1),_c('router-link',{attrs:{"to":("/" + (_vm.$i18n.locale))}},[_c('post-button',{staticClass:"background-color-black color-white text-nowrap mt-huge",staticStyle:{"margin-left":"auto"},attrs:{"size":"large"}},[_vm._v(_vm._s(_vm.$t("general.digitalhealth")))])],1)],1)],1):_vm._e()],1),_c('transition',{attrs:{"name":"expand-y"}},[(!_vm.tokenValidityError)?_c('validation-observer',{ref:"validationObserver",staticStyle:{"overflow-x":"hidden"},attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('row',[_c('column',[_c('validation-provider',{attrs:{"mode":"lazy","rules":{ required: true, password: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('label',{staticClass:"mt-big"},[_vm._v(_vm._s(_vm.$t("login.passwordlabel"))+"*")]),_c('text-login',{attrs:{"type":"password","autocomplete":"new-password","valid":validated ? !errors.length : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('p',{staticClass:"font-weight-light my-small text-mini"},[_vm._v(_vm._s(_vm.$t("login.passrequire")))])]}}],null,true)}),_c('validation-provider',{attrs:{"mode":"lazy","rules":{ required: true, passwordSame: { existingPassword: _vm.password } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('label',{staticStyle:{"margin-top":"12px"}},[_vm._v(_vm._s(_vm.$t('login.repeat'))+"*")]),_c('text-login',{staticClass:"w-100 w-lg-60",attrs:{"valid":validated ? !errors.length : null,"type":"password","autocomplete":"new-password"},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}})]}}],null,true)})],1)],1),_c('row',[_c('transition',{attrs:{"name":"expand-y"}},[(invalid && _vm.submitAttempt)?_c('column',{staticClass:"mt-regular",attrs:{"col":"12"}},[_c('post-alert',{staticClass:"color-white background-color-error",scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('icon',[_vm._v("M")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("general.formError"))+" ")])],1):_vm._e()],1),_c('transition',{attrs:{"name":"expand-y"}},[(_vm.error)?_c('row',{staticClass:"no-gutters px-regular"},[_c('column',{staticClass:"py-regular mt-regular",attrs:{"col":"12"}},[_c('post-alert',{staticClass:"color-white background-color-error",scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('icon',[_vm._v("M")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("general.error"))+" ")])],1)],1):_vm._e()],1)],1),_c('row',{staticClass:"mt-large"},[_c('column',{staticClass:"grow"}),_c('column',{staticClass:"shrink"},[_c('router-link',{attrs:{"to":("/" + (_vm.$i18n.locale))}},[_c('post-button',{attrs:{"size":"large","text":""}},[_vm._v(_vm._s(_vm.$t("general.cancel")))])],1)],1),_c('column',[_c('post-button',{staticClass:"background-color-black color-white",attrs:{"size":"large","type":"submit","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("login.login"))+" ")])],1)],1)]}}],null,false,3095654563)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }