






















































import { Component } from "vue-property-decorator"
import serviceList from "@/components/ServiceList.vue"
import ContactTeaser from "@/components/ContactTeaser.vue"
import HeroIntro from "@/components/post/HeroHeader.vue"
import ApiMixin from "@/views/apiMixin"
import { MetaInfo } from "vue-meta"
import Error from "@/components/Error.vue"
import textLogin from "@/components/post/TextLogin.vue"
import postButton from "@/components/post/Button.vue"
import axios from "axios"
import { User } from "@/api"
import { genHead } from "@/helpers"

Component.registerHooks(["metaInfo"])

@Component({ components: { serviceList, HeroIntro, ContactTeaser, Error, textLogin, postButton } })
export default class AdminInterface extends ApiMixin {
    metaInfo(): MetaInfo { return genHead.bind(this)() }


    data: User[] = []
    async fetch(): Promise<void> {
        await axios.get(
            `${this.$configBackendApi}/user/all`,
            // { headers: { "X-AUTH-TOKEN": this.$cookies.get("auth.token") } }
        )
            .then(res => this.data = res.data)
    }
}
