
















import {Component, Prop} from "vue-property-decorator"
import valueMixin from "@/components/valueMixin"

@Component
export default class Checkbox extends valueMixin {
    // @ts-ignore
    val!: boolean

    @Prop({default: false, required: false}) noBorder!: boolean
    @Prop({default: "checkbox", required: false}) role!: string
}
