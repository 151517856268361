


















import {Component, Prop} from "vue-property-decorator"
import checkbox from "@/components/post/Checkbox.vue"
import list from "@/components/post/List.vue"
import valueMixin from "@/components/valueMixin"

@Component({components: {checkbox, list}})
export default class CheckboxGroup extends valueMixin {
    // @ts-ignore
    val!: string[]

    @Prop() options!: string[]
    @Prop({default: false, required: false}) noBorder!: boolean

    input(option: string): void {
        if (this.val.includes(option)) {
            this.val = this.val.filter((v: string) => v !== option)
        } else {
            this.val = [...this.val, option]
        }
    }
}
