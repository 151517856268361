


































































import {Component, Vue, Prop} from "vue-property-decorator"
import card from "@/components/post/Card.vue"
import pill from "@/components/Pill.vue"
import {timeoutPromise} from "@/helpers"
import shave from "shave"

@Component({components: {card, pill}})
export default class ServiceCard extends Vue {
    @Prop({required: true, default: "nightblue"}) color!: string
    @Prop({required: false, type: Number, default: null}) index!: string
    hovered = false
    clickAnimation = false
    randomId = Math.round(Math.random() * 1000)

    // add delay in click animation to make it prettier
    async onClick(): Promise<void> {
        this.clickAnimation = true
        this.$emit("click")
        await timeoutPromise(200)
        this.clickAnimation = false
    }

    categoryShaver(): void {
        shave(`#category-${this.index === null ? this.randomId : this.index}`, 50, { character: "…" })
    }

    mounted(): void {
        this.categoryShaver()
        window.addEventListener("resize", this.categoryShaver)
    }

    beforeDestroy(): void {
        window.removeEventListener("resize", this.categoryShaver)
    }
}
