






import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class Container extends Vue {
    @Prop({default: false}) fluid!: boolean
}
