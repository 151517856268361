
















































































































































































































































































import { Component, Watch } from "vue-property-decorator"
import ApiMixin from "@/views/apiMixin"
import postDialog from "@/components/post/Dialog.vue"
import postButton from "@/components/post/Button.vue"
import postAccordeon from "@/components/post/Accordeon.vue"
import service from "@/components/Service.vue"
import pill from "@/components/Pill.vue"
import postFileList from "@/components/post/FileList.vue"
import error from "@/components/Error.vue"
import ServiceClass from "@/types/service"
// @ts-ignore
import Chocolat from "chocolat"
import { colorMap, genHead } from "@/helpers"
import { serviceResponse } from "@/types/meshInterfaces"
import { AxiosResponse } from "axios"
import FileList from "@/components/post/FileList.vue"
import { MetaInfo } from "vue-meta"

@Component({
    components: {
        postFileList,
        postDialog,
        postAccordeon,
        service,
        postButton,
        pill,
        error,
        FileList,
    },
})
export default class ServiceDetails extends ApiMixin {
    // @ts-ignore
    $refs!: { subTitle: HTMLDivElement, "chocolat-image"?: HTMLAnchorElement }

    value: ServiceClass | null = null
    connectedServices: ServiceClass[] = []
    technicalDependencyServices: ServiceClass[] = []
    dialogOpen = false
    contentScrolled = false
    current = new Date()
    color = ""
    colorMap = colorMap
    subtitleHeight = 0

    metaInfo(): MetaInfo {
        return genHead.bind(this)(
            this.value?.metaTitle || this.$t(`meta.title.${this.$route.name}`).toString(),
            this.value?.metaDescription,
            this.fetchState.pending || !!this.fetchState.error,
        )
    }

    async mountDelay(): Promise<void> {
        this.contentScrolled = false
        setTimeout(() => {
            const dialogContent = Array.from(
                document.getElementsByClassName("dialog-content")
            ) as HTMLDivElement[]

            dialogContent.forEach((element) => {
                if (this.$refs.subTitle) {
                    this.$refs.subTitle.style.maxHeight = "unset"
                    this.contentScrolled = false

                    let touchScreen = 0
                    const contentContent = element.classList.contains("content") ? element : element.getElementsByClassName("content")[2]

                    element.addEventListener("touchstart", (evt: TouchEvent) => (touchScreen = evt.touches[0].clientY), { passive: true })

                    element.addEventListener(
                        "touchmove",
                        (evt: TouchEvent) => {
                            const te = evt.changedTouches[0].clientY
                            if (touchScreen > te + 5) {
                                // slide down
                                this.contentScrolled = true
                                this.$refs.subTitle.style.maxHeight = "0"
                            } else if (touchScreen < te - 5 && contentContent.scrollTop < 15) {
                                // slide up
                                this.contentScrolled = false
                                this.$refs.subTitle.style.maxHeight = `${this.subtitleHeight}px`
                            }
                        },
                        { passive: true }
                    )

                    this.subtitleHeight = this.$refs.subTitle.offsetHeight
                    this.$refs.subTitle.style.maxHeight = `${this.subtitleHeight}px`

                    element.addEventListener(
                        "wheel",
                        (evt: WheelEvent) => {
                            if (evt.deltaY > 0) {
                                this.contentScrolled = true
                                this.$refs.subTitle.style.maxHeight = "0"
                            } else if (contentContent.scrollTop < 15) {
                                this.contentScrolled = false
                                this.$refs.subTitle.style.maxHeight = `${this.subtitleHeight}px`
                            }
                        },
                        { passive: true }
                    )
                }
            })
        }, 300)
    }

    close(): void {
        setTimeout(() => {
            this.$router.push(`/${this.$i18n.locale}`)
            this.$emit("close")
        }, 200)
    }

    @Watch("$route.params.service") onServiceChange(): void {
        this.$fetch()
    }

    get image(): {
        url: string
        previewUrl?: string
        color: string
        width?: number | null
        height?: number | null
    } | null {
        return this.value?.image?.binaryUuid
            ? {
                url: `${this.$configGenticsApi}/digitalhealth/nodes/${this.value.uuid}/binary/image?lang=${this.$i18n.locale}`,
                previewUrl: `${this.$configGenticsApi}/digitalhealth/nodes/${this.value.uuid}/binary/image?lang=${this.$i18n.locale}&w=600`,
                color: this.value?.image?.dominantColor || "",
                width: this.value?.image?.width || null,
                height: this.value?.image?.height || null,
            }
            : null
    }

    get partnerLogo(): {
        url: string
        previewUrl?: string
        color: string
        width?: number | null
        height?: number | null
    } | null {
        return this.value?.partnerLogo?.binaryUuid
            ? {
                url: `${this.$configGenticsApi}/digitalhealth/nodes/${this.value.uuid}/binary/partnerLogo?lang=${this.$i18n.locale}`,
                previewUrl: `${this.$configGenticsApi}/digitalhealth/nodes/${this.value.uuid}/binary/partnerLogo?lang=${this.$i18n.locale}&w=600`,
                color: this.value?.partnerLogo?.dominantColor || "",
                width: this.value?.partnerLogo?.width || null,
                height: this.value?.partnerLogo?.height || null,
            }
            : null
    }

    async fetch(): Promise<void> {
        const serviceData = await this.graphql(
            `{
                node(uuid: "114c8fe880664f328312996a86d5521a", lang: "${this.$i18n.locale}") {
                    children(filter: {schema: {is: healthService}, fields: {
                        healthService: {id: {equals: "${this.$route.params.service}"}}}}) {
                        elements {
                            isPublished
                            ... on healthService { uuid created fields {
                                area
                                benefit
                                category
                                description
                                extraAccordeon { ... on extraAccordeon { fields { title content }}}
                                filesPublic { ... on downloadFiles { fields { name file { uuid created edited displayName }}}}
                                focus
                                id
                                image{ ... on BinaryField { binaryUuid }}
                                label
                                name
                                problemwesolve
                                professionalsynergies
                                tags
                                technicalDependency
                                userstory
                                metaTitle
                                metaDescription
                                namedUrl
                                partnerLogo{ ... on BinaryField { binaryUuid }}
                            }}
                        }
                    }
                }
            }`
        )

        // @ts-ignore
        if (serviceData.data.data.node.children.elements[0]) {
            this.value = new ServiceClass(
                // @ts-ignore
                serviceData.data.data.node.children.elements[0],
            )

            if ((!this.$route.params.namedUrl || this.$route.params.namedUrl !== this.value.namedUrl) && this.value.namedUrl) {
                this.$router.replace({ path: `/${this.$i18n.locale}/services/${this.value.id}/${this.value.namedUrl}`, query: this.$route.query })
            }

            window.localStorage.setItem(`last-seen-${this.value.id}`, new Date().getTime().toString())

            const noSpace = this.value.category.trim().replaceAll("\u00AD", "").replaceAll("\u00AD", " ").trim()
            const withSpace = this.value.category.trim().replaceAll("\u00AD", " ")
            this.color = this.colorMap[noSpace] || this.colorMap[withSpace] || "nightblue"

            await Promise.all([
                this.graphql(
                    `{
                    node(uuid: "114c8fe880664f328312996a86d5521a", lang: "${this.$i18n.locale}") {
                        children(filter: {schema: {is: healthService}}) {
                            elements {
                                ... on healthService {
                                    created
                                    isPublished
                                    fields { id label category name namedUrl}
                                }
                            }
                        }
                    }
                }`
                )
                    .then(res =>
                        (res as AxiosResponse<serviceResponse>).data.data.node.children.elements.filter(el => el.isPublished)
                    )
                    .then(res =>
                        res
                            .map(serviceData => new ServiceClass(serviceData))
                            .sort((a: ServiceClass, b: ServiceClass) => b.created.getTime() - a.created.getTime())
                    )
                    .then(res => {
                        const synergies = this.value?.professionalsynergies || []
                        this.connectedServices = synergies.length ? res.filter(service => synergies.find(syn => service.id.includes(syn))) : []

                        const dependencies = this.value?.technicalDependency || []
                        let baseDependencyServices: ServiceClass[] = []
                        const dependencyServices = dependencies.length ? res.filter(service => dependencies.find(dep => service.id.includes(dep))) : []
                        this.technicalDependencyServices = dependencyServices.concat(baseDependencyServices)
                    }),
            ])
        } else {
            this.fetchState.error = "true"
            this.$track({
                event: "error_message",
                type: "node_not_found",
                error_message: "The element was not found in the CMS",
                error_code: "404",
            })
        }

        this.dialogOpen = true
        await this.$nextTick()
        this.mountDelay()

        setTimeout(() => {
            this.$refs["chocolat-image"] && Chocolat(document.querySelectorAll(".chocolat-image"), {
                linkImages: false,
                afterClose: () => {
                    document.body.style.overflow = "hidden"
                }
            })
        }, 500)
    }

    mountDelayTimeout: unknown = null
    created(): void {
        document.body.style.overflow = "hidden"
        window.addEventListener("resize", this.mountDelayTimeoutReset, { passive: true })
    }

    mountDelayTimeoutReset(): void {
        // @ts-ignore
        this.mountDelayTimeout && clearTimeout(this.mountDelayTimeout)
        this.mountDelayTimeout = setTimeout(this.mountDelay, 250)
    }

    beforeDestroy(): void {
        document.body.style.overflow = ""
        window.removeEventListener("resize", this.mountDelayTimeoutReset)

    }
}
