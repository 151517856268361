


























































































































































































































































































import { Component } from "vue-property-decorator"
import PostInput from "@/components/post/Input.vue"
import PostBreadcrumbs from "@/components/post/Breadcrumbs.vue"
import PostLine from "@/components/post/Line.vue"
import PostSelect from "@/components/post/Select.vue"
import PostList from "@/components/post/List.vue"
import PostButton from "@/components/post/Button.vue"
// import PostUpload from "@/components/post/Upload.vue"
import PostRadio from "@/components/post/Radio.vue"
import PostAlert from "@/components/post/Alert.vue"
import ApiMixin from "@/views/apiMixin"
import { MetaInfo } from "vue-meta"
import axios, { AxiosResponse } from "axios"
import { serviceResponse } from "@/types/meshInterfaces"
import Service from "@/types/service"
import { colorMap, genHead, removeSpecial } from "@/helpers"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

Component.registerHooks(["metaInfo"])

interface orderedService {
    category: string
    color: string
    services: Service[]
}

extend("required", required);
extend("email", email);


@Component({
    components: {
        PostInput,
        PostButton,
        // PostUpload,
        PostBreadcrumbs,
        PostLine,
        PostSelect,
        PostList,
        PostRadio,
        PostAlert,
        ValidationProvider,
        ValidationObserver
    },
})
export default class Contact extends ApiMixin {
    metaInfo(): MetaInfo { return genHead.bind(this)() }

    $refs!: {validationObserver: HTMLSpanElement, requestTextarea: HTMLTextAreaElement}

    product: Service = new Service()
    request = ""
    gender = ""
    firstName = ""
    lastName = ""
    company = ""
    eMail = ""
    tel = ""

    services: Service[] = []
    emailTouched = false
    submitAttempt = 0
    loading = false

    get orderedServices(): orderedService[] {
        const orderedServices: orderedService[] = []
        this.services.forEach((service) => {
            const existing = orderedServices.find(
                (order) => order.category === service.category
            )
            if (existing) {
                existing.services.push(service)
            } else {
                orderedServices.push({
                    category: service.category,
                    color: colorMap[removeSpecial(service.category)],
                    services: [service],
                })
            }
        })
        return orderedServices
    }

    handleTextarea(): void {
        this.$refs.requestTextarea.style.height = this.$refs.requestTextarea.scrollHeight + "px"
    }

    async fetch(): Promise<void> {
        this.services = await this.graphql(
            `{
                node(uuid: "114c8fe880664f328312996a86d5521a", lang: "${this.$i18n.locale}") {
                    children(filter: {schema: {is: healthService}}) {
                        elements { ... on healthService { isPublished fields { id category name }}}
                    }
                }
            }`
        )
            .then((res) =>
                (res as AxiosResponse<serviceResponse>).data.data.node.children.elements.filter(
                    (el) => el.isPublished
                )
            )
            .then((res) => res.map((element) => new Service(element)))
    }

    async checkValidation(): Promise<void> {
        // @ts-ignore
        const validated = await this.$refs.validationObserver.validate()
        this.submitAttempt++

        if (validated) {
            this.loading = true
            axios.post(`${this.$configBackendApi}/contact`, {
                "request": this.request,
                "gender": this.gender,
                "firstName": this.firstName,
                "lastName": this.lastName,
                "company": this.company,
                "email": this.eMail,
                "tel": this.tel,
                "produkt": `${this.product.id} ${this.product.name}`,
            })
                .then(() => {
                    this.$track({ event: "form_submit", type: "contact_form", label: "Contact" })
                    this.$router.push(`/${this.$i18n.locale}/contact/success`)
                })
                .catch(() => this.$router.push(`/${this.$i18n.locale}/contact/error`))
                .finally(() => this.loading = false)
        } else {
            this.$track({
                event: "error_message",
                type: "form_error",
                // @ts-ignore
                error_message: `${(this.$t("general.inputError") as string).toLocaleLowerCase()}: ${Object.keys(this.$refs.validationObserver.errors).join(",")}`,
            })
        }
    }
}
