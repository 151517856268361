










import { MetaInfo } from "vue-meta"
import { Component, Vue } from "vue-property-decorator"
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"

Component.registerHooks(["metaInfo"])

@Component({ components: { Header, Footer } })
export default class App extends Vue {
    metaInfo(): MetaInfo {
        return {
            titleTemplate: this.$route.name === "app" || this.$route.name === "home" ? "%s" : `%s | ${this.$t("meta.titleAppend")}`,
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
        }
    }
}
