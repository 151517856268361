


































import { Component, Prop, Vue } from "vue-property-decorator"

export interface SrcSet {
    src: string,
    minWidth?: number
    type: string
}

@Component
export default class HeroIntro extends Vue {
    @Prop({ type: String, required: false, default: "" }) readonly title!: string
    @Prop({ type: String, required: false, default: "" }) readonly subtitle!: string
    @Prop({ type: Array, required: false, default: () => [] }) readonly srcSets!: SrcSet[]
    @Prop({ type: Object, required: true }) readonly src!: Omit<SrcSet, "minWidth">
    @Prop({ type: Boolean, required: false, default: true }) fullWidth!: boolean

    logoWidth = 208.8

    mounted(): void {
        setTimeout(this.setLogoWidth, 50)
        window.addEventListener("resize", this.setLogoWidth, { passive: true })
    }

    beforeDestroy(): void {
        window.removeEventListener("resize", this.setLogoWidth)
    }

    setLogoWidth(): void {
        this.logoWidth = document.getElementById("post-logo")?.offsetWidth || 208.8
    }
}
