



































// https://www.experience-hub.ch/document/2401#/navigation/akkordeon

import { Component, Prop } from "vue-property-decorator"
import ValueMixin from "@/components/valueMixin"
import postButton from "@/components/post/Button.vue"

@Component({ components: { postButton } })
// @ts-ignore
export default class PostAccordeon extends ValueMixin {
    @Prop({ required: false }) value!: boolean
    @Prop({ default: "" }) title!: string

    fallbackValue = false
    hovered = false
}
