












































// https://www.experience-hub.ch/document/2385#/komponenten-1/eingabefelder

import { Component, Prop } from "vue-property-decorator"
import ValueMixin from "@/components/valueMixin"

@Component
export default class PostInput extends ValueMixin {
    // @ts-ignore
    val!: string
    @Prop({ default: "", required: false }) label!: string
    @Prop({ default: "", required: false }) message!: string
    @Prop({ default: "", required: false }) errorMessage!: string
    @Prop({ default: null, required: false }) valid!: boolean | null
    @Prop({ default: "text", required: false }) type!: string
    @Prop({ default: "", required: false }) role!: string

    focus = false

    focused(): void {
        this.focus = true
    }

    blured(): void {
        this.focus = false
        this.$emit("blur")
    }
}
