import { cleanFields, replaceSpecial } from "@/helpers"
import {
    accordeon,
    downloadFile,
    image,
    rawService,
} from "@/types/meshInterfaces"
import store from "@/store/index"

export default class {
    uuid!: string
    edited!: Date
    created!: Date
    area!: string[]
    benefit!: string
    category!: string
    contact!: string | null
    description!: string
    extraAccordeon!: accordeon | null
    filesPrivate!: downloadFile[]
    filesPublic!: downloadFile[]
    focus!: string[]
    id!: string
    image!: image | null
    label!: string
    name!: string
    problemwesolve!: string
    professionalsynergies!: string[]
    tags!: string[]
    technicalDependency!: string[]
    userstory!: string
    isPublished!: boolean
    metaTitle!: string
    metaDescription!: string
    namedUrl: string | null = null
    partnerLogo!: image | null

    constructor(rawData?: rawService) {
        if (rawData) {
            // @ts-ignore
            const cleanData = cleanFields(rawData)

            for (const key in cleanData) {
                if (Object.prototype.hasOwnProperty.call(cleanData, key)) {
                    if (key === "created" || key === "edited") {
                        // @ts-ignore
                        this[key] = new Date(cleanData[key])
                    } else if (key.startsWith("files")) {
                        // @ts-ignore
                        this[key] = (cleanData[key] as downloadFile[] | null)?.filter(file => file?.file)
                    } else {
                        // @ts-ignore
                        this[key] = replaceSpecial(cleanData[key])
                    }
                }
            }

            !this.metaDescription && (this.metaDescription = this.description)
            !this.metaTitle && (this.metaTitle = this.name)
        } else {
            this.uuid = ""
            this.id = ""
        }
    }

    // no of days after update that the service is considered "new"
    private newDays = 30

    get isNew(): boolean {
        if (this.id) {
            const diffTime = (new Date()).getTime()
                - (this.created || new Date()).getTime()
            return diffTime / 1000 / 60 / 60 / 24 < this.newDays
        } else return false
    }

    get isNewDocuments(): boolean {
        let returnVal = false
        if ((this.filesPrivate && this.filesPrivate.length && this.id)) {
            const lastSeen = (new Date(store.state.user?.lastLogin || 0)).getTime()
            const newestDate = Math.max(...this.filesPrivate.filter(file => file?.file?.created).map(file => new Date(file.file.created).getTime()))

            returnVal = newestDate > lastSeen
        }
        if (!returnVal && (this.filesPublic && this.filesPublic.length && this.id)) {
            const lastSeen = (new Date(store.state.user?.lastLogin || 0)).getTime()
            const newestDate = Math.max(...this.filesPublic.filter(file => file?.file?.created).map(file => new Date(file.file.created).getTime()))

            returnVal = newestDate > lastSeen
        }
        return returnVal
    }
}
