

















import { Component } from "vue-property-decorator"
import { MetaInfo } from "vue-meta"
import ApiMixin from "@/views/apiMixin"
import axios from "axios"
import PostAlert from "@/components/post/Alert.vue"
import { genHead } from "@/helpers"

Component.registerHooks(["metaInfo"])

@Component({ components: { PostAlert } })
export default class Confirm extends ApiMixin {
    metaInfo(): MetaInfo { return genHead.bind(this)() }

    async fetch(): Promise<void> {
        await axios.get(`${this.$configBackendApi}/user/confirmuser/${this.$route.params.token}`)
            .then(() => this.$router.replace(`/${this.$i18n.locale}/login/mailsuccess`))
    }
}
