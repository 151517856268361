/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Vue, Component } from "vue-property-decorator"
import axios, { AxiosResponse } from "axios"
import { adResponse, GraphQLError, rawDownloadableResponse, rawDownloadListResponse, rawFileResponse, serviceResponse } from "@/types/meshInterfaces"

// This mixin provides a function "$fetch" and an object "fetchState".
// $fetch calls an async method "fetch" is available and updates the fetchState variable.
// That way, an async "fetch" function can be written in the component and called via $fetch.
// The state (loading, error etc) of say, an API call, can be monitored via the fetchState variable.
@Component
export default class ApiMixin extends Vue {
    fetchState = {
        pending: false,
        error: null as null | Error | string,
    }

    async $fetch(): Promise<void> {
        this.fetchState.error = null
        this.fetchState.pending = true
        try {
            // @ts-ignore
            if (this.fetch) {
                // @ts-ignore
                await this.fetch()
            }
        } catch (err) {
            this.fetchState.error = err as string
            throw new Error(err as string)
        } finally {
            this.fetchState.pending = false
        }
    }

    created(): void {
        this.$fetch()
    }

    graphql(query: string, token = false): Promise<AxiosResponse<adResponse | serviceResponse | rawDownloadableResponse | rawFileResponse | GraphQLError | rawDownloadListResponse>> {
        return axios.post(
            `${this.$configGenticsApi}/digitalhealth/graphql`,
            { query },
            token ? { withCredentials: true } : {}
        )
            .then(res => {
                if (res.data.errors) { throw new Error(JSON.stringify(res.data.errors)) }
                return res
            })
    }
}
