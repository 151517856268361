






import {Component, Vue, Prop} from "vue-property-decorator"

@Component
export default class Icon extends Vue {
    @Prop({default: "large", required: false}) size!:
        | "tiny"
        | "small"
        | "regular"
        | "biggerregular"
        | "medium"
        | "large"
        | "smallbig"
        | "big"
        | "biggerbig"
        | "smallhuge"
        | "huge"
    @Prop({default: "inherit", required: false}) color!: string
}
