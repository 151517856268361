






































































































import { Component } from "vue-property-decorator"
import ApiMixin from "@/views/apiMixin"
import postDialog from "@/components/post/Dialog.vue"
import postButton from "@/components/post/Button.vue"
import postAccordeon from "@/components/post/Accordeon.vue"
import service from "@/components/Service.vue"
import pill from "@/components/Pill.vue"
import error from "@/components/Error.vue"
import ServiceClass from "@/types/service"
import { colorMap, genHead } from "@/helpers"
import FileList from "@/components/post/FileList.vue"
import { MetaInfo } from "vue-meta"

@Component({
    components: {
        FileList,
        postDialog,
        postAccordeon,
        service,
        postButton,
        pill,
        error
    },
})
export default class ServiceDetails extends ApiMixin {
    value: ServiceClass | null = null
    dialogOpen = false
    contentScrolled = false
    current = new Date()
    color = ""
    colorMap = colorMap
    subtitleHeight = 0

    metaInfo(): MetaInfo { return genHead.bind(this)(this.value?.metaTitle || this.$t(`meta.${this.$route.name}`).toString(), this.value?.metaDescription || this.$t(`meta.${this.$route.name}`).toString()) }

    close(): void {
        setTimeout(() => {
            this.$router.push(`/${this.$i18n.locale}/restricted/documents`)
            this.$emit("close")
        }, 200)
    }

    get partnerLogo(): {
        url: string
        previewUrl?: string
        color: string
        width?: number | null
        height?: number | null
    } | null {
        return this.value?.partnerLogo?.binaryUuid
            ? {
                url: `${this.$configGenticsApi}/digitalhealth/nodes/${this.value.uuid}/binary/partnerLogo?lang=${this.$i18n.locale}`,
                previewUrl: `${this.$configGenticsApi}/digitalhealth/nodes/${this.value.uuid}/binary/partnerLogo?lang=${this.$i18n.locale}&w=600`,
                color: this.value?.partnerLogo?.dominantColor || "",
                width: this.value?.partnerLogo?.width || null,
                height: this.value?.partnerLogo?.height || null,
            }
            : null
    }

    async fetch(): Promise<void> {
        const serviceData = await this.graphql(
            `{
                node(uuid: "114c8fe880664f328312996a86d5521a", lang: "${this.$i18n.locale}") {
                    children(filter: {schema: {is: healthService}, fields: {
                        healthService: {id: {equals: "${this.$route.params.service}"}}}}) {
                        elements {
                            isPublished
                            ... on healthService { uuid fields {
                                category
                                description
                                filesPublic { ... on downloadFiles { fields { name file { uuid created edited displayName }}}}
                                filesPrivate { ... on downloadFiles { fields { name file { uuid created edited displayName }}}}
                                id
                                label
                                name
                                metaTitle
                                metaDescription
                                namedUrl
                                partnerLogo{ ... on BinaryField { binaryUuid }}
                            }}
                        }
                    }
                }
            }`
            , true)

        // @ts-ignore
        if (serviceData.data.data.node.children.elements[0]) {
            this.value = new ServiceClass(
                // @ts-ignore
                serviceData.data.data.node.children.elements[0]
            )

            if ((!this.$route.params.namedUrl || this.$route.params.namedUrl !== this.value.namedUrl) && this.value.namedUrl) {
                this.$router.replace({ path: `/${this.$i18n.locale}/restricted/documents/${this.value.id}/${this.value.namedUrl}`, query: this.$route.query })
            }

            window.localStorage.setItem(`last-seen-documents-${this.value.id}`, new Date().getTime().toString())

            const noSpace = this.value.category.trim().replaceAll("\u00AD", "").replaceAll("\u00AD", " ").trim()
            const withSpace = this.value.category.trim().replaceAll("\u00AD", " ")
            this.color = this.colorMap[noSpace] || this.colorMap[withSpace] || "nightblue"
        } else {
            this.fetchState.error = "true"
            this.$track({
                event: "error_message",
                type: "node_not_found",
                error_message: "The element was not found in the CMS",
                error_code: "404",
            })
        }

        this.dialogOpen = true
    }

    created(): void {
        document.body.style.overflow = "hidden"
    }

    beforeDestroy(): void {
        document.body.style.overflow = ""
    }

    get hasFiles(): boolean {
        return !!(this.value?.filesPrivate?.some(f => f.file) || this.value?.filesPublic?.some(f => f.file))
    }
}
