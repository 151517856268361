





































































































































































































import { Component, Watch } from "vue-property-decorator"
import serviceList from "@/components/ServiceList.vue"
import ContactTeaser from "@/components/ContactTeaser.vue"
import HeroIntro from "@/components/post/HeroHeader.vue"
import ApiMixin from "@/views/apiMixin"
import { MetaInfo } from "vue-meta"
import Error from "@/components/Error.vue"
import textLogin from "@/components/post/TextLogin.vue"
import postButton from "@/components/post/Button.vue"
import checkBox from "@/components/post/Checkbox.vue"
import PostAlert from "@/components/post/Alert.vue"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import postRadio from "@/components/post/Radio.vue"
import { UserService } from "@/api"
import { email, required } from "vee-validate/dist/rules"
import { ValidationRule } from "vee-validate/dist/types/types"
import { genHead } from "@/helpers"

Component.registerHooks(["metaInfo"])

extend("required", required)
extend("email", email)
extend("endsdeen", {
    validate: (value: string) => {
        return value.toLocaleLowerCase().endsWith(".de") || value.toLocaleLowerCase().endsWith(".com")
    },
    message: "Ihre E-Mail-Adresse muss auf .de oder .com enden",
} as ValidationRule)
extend("password", {
    validate: (value: string) => {
        const minTwelve = /.{12,}/.test(value)
        const other = [/[A-Z]/.test(value), /[a-z]/.test(value), /\d/.test(value), /[^\w\s\d]/.test(value)]
        return minTwelve && other.filter(o => o).length >= 3
    },
    message: "Ihr Passwort entspricht nicht den Bedingungen",
} as ValidationRule)
extend("passwordSame", {
    params: ["existingPassword"],
    validate: (value: string, { existingPassword }: { existingPassword: string }) => value === existingPassword,
    message: "Passwort nicht korrekt wiederholt.",
} as ValidationRule)


@Component({ components: { serviceList, HeroIntro, ContactTeaser, Error, textLogin, postButton, checkBox, postRadio, PostAlert, ValidationProvider, ValidationObserver } })
export default class loginRegister extends ApiMixin {
    $refs!: {validationObserver: HTMLSpanElement}

    metaInfo(): MetaInfo { return genHead.bind(this)() }

    // reload data on language change
    @Watch("$i18n.locale") onLocaleChange(): void {
        this.$fetch()
    }

    gender = ""
    firstname = ""
    lastname = ""
    occupation = ""
    employeeNumber = ""
    email = ""
    password = ""
    passwordRepeat = ""
    submitAttempt = 0
    error = false
    loading = false

    async register(): Promise<void> {
        // @ts-ignore
        const validated = await this.$refs.validationObserver.validate()
        this.submitAttempt++
        this.error = false

        if (validated) {
            this.loading = true
            await UserService.createUser({
                json: {
                    title: this.gender,
                    firstName: this.firstname,
                    lastName: this.lastname,
                    occupation: this.occupation,
                    employeeNumber: this.employeeNumber,
                    email: this.email,
                    // @ts-ignore
                    password: this.password,
                    lang: this.$i18n.locale
                }
            })
                .then(() => {
                    this.$track({ event: "sign_up", method: "sales_login", secondary_segment: this.employeeNumber })
                    this.$router.replace(`/${this.$i18n.locale}/login/regsuccess`)
                })
                .catch(() => this.error = true)
                .finally(() => this.loading = false)
        } else {
            this.$track({
                event: "error_message",
                type: "form_error",
                // @ts-ignore
                error_message: `${(this.$t("general.inputError") as string).toLocaleLowerCase()}: ${Object.keys(this.$refs.validationObserver.errors as Record<string, string>).join(",")}`,
            })
        }
    }
}
