


















































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import filterMenu from "@/components/FilterMenu.vue"
import serviceCard from "@/components/ServiceCard.vue"
import postButton from "@/components/post/Button.vue"
import postInput from "@/components/post/Input.vue"
import adCard from "@/components/AdCard.vue"
import pill from "@/components/Pill.vue"
import ServiceClass from "@/types/service"
import Service from "@/components/Service.vue"
import { timeoutPromise } from "@/helpers"
import Ad from "@/types/ad"
import Container from "./layout/Container.vue"

@Component({
    components: {
        filterMenu,
        serviceCard,
        postInput,
        postButton,
        pill,
        adCard,
        Service,
    },
})
export default class ServiceList extends Vue {
    @Prop({ required: true, default: () => [] }) items!: ServiceClass[]
    @Prop({ required: true, default: () => [] }) filteredItems!: ServiceClass[]
    @Prop({ required: false, default: () => [] }) ads!: Ad[]
    @Prop({ required: false, default: false }) filterApplied!: boolean
    @Prop({ required: false, default: () => [] }) filters!: {
        [key: string]: { name: string; options: string[]; selected: string[] }
    }
    @Prop({ required: false, default: () => ({ name: "", options: [], selected: [] }) }) sort!: {
        name: string
        options: string[]
        selected: string[]
    }
    @Prop({ required: false, default: "" }) search!: string
    @Prop({ required: false, type: Boolean, default: false }) hideFilters!: boolean

    $refs!: { list: Container }


    delayedItems: Array<ServiceClass> = []
    visible = false

    // all filter items independant of filter (not necessarily applied)
    get filterSelectedDisplay(): Array<{ value: string; filter: string }> {
        return Object.entries(this.filters)
            .map((data) =>
                data[1].selected.map((sel) => ({ value: sel, filter: data[0] }))
            )
            .flat()
    }

    mounted(): void {
        // apply items and animation once scrolled into view
        const observer = new IntersectionObserver((entry) => {
            if (entry[0].isIntersecting) {
                this.visible = true
            }
        })
        observer.observe(this.$refs.list.$el)
    }

    @Watch("items", { immediate: true }) onItemsChange(): void {
        this.itemsDelay()

    }

    // adds small delay between service display
    async itemsDelay(): Promise<void> {
        await this.$nextTick()
        this.delayedItems = []
        let timeout = -100
        this.filteredItems.forEach(async (item) => {
            timeout += 100
            await timeoutPromise(timeout)
            this.delayedItems.push(item)
        })
        this.$emit("filter-apply")
    }

    // apply ads and filter titles to list
    get displayItems(): Array<ServiceClass | Ad | { type: string; value: string; }> {
        if (
            !this.filterApplied &&
            this.items.length === this.delayedItems.length
        ) {
            const items: Array<ServiceClass | Ad> = this.filteredItems
            if (
                this.items.length &&
                this.items.length === this.delayedItems.length
            ) {
                this.ads.forEach((ad, i) => {
                if(items.length >= (i + 1) * 6 - i){
                    items.splice((i + 1) * 6 - i, 0, ad)
                } else {
                    items.push(ad)
                }
            })
            }
            return items
        } else if (this.delayedItems.length === this.items.length) {
            const filteredIds = this.filteredItems.map((item) => item.id)
            const excludedServices: Array<ServiceClass | Ad> = this.items.filter(
                (unfilteredItem) => !filteredIds.includes(unfilteredItem.id)
            )

            this.ads.forEach((ad, i) => {
                if(excludedServices.length >= (i + 1) * 6 - i){
                    excludedServices.splice((i + 1) * 6 - i, 0, ad)
                } else {
                    excludedServices.push(ad)
                }
            })

            return ([{
                type: "heading",
                value: this.$t("list.result").toString()
            }] as Array<ServiceClass | Ad | { type: string, value: string }>)
                .concat(this.filteredItems.length ? this.filteredItems : [{
                    type: "copy",
                    value: this.$t("filter.notavailable").toString()
                }])
                .concat(excludedServices.length ? [{ type: "heading", value: this.$t("list.all").toString() }] : [])
                .concat(excludedServices)
        } else {
            return this.delayedItems
        }
    }

    // open ads in a different tab
    openAd(url: string): void {
        window.open(url, url.startsWith("/") ? "_self" : "_blank")
    }
}
