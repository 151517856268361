



















































// https://www.experience-hub.ch/document/2385#/komponenten-1/select

import {Component, Prop} from "vue-property-decorator"
import ValueMixin from "@/components/valueMixin"
import PostInput from "@/components/post/Input.vue"
import PostList from "@/components/post/List.vue"
import PostCard from "@/components/post/Card.vue"
// @ts-ignore
import ClickOutside from "vue-click-outside"

@Component({
    components: {PostInput, PostList, PostCard},
    directives: {ClickOutside},
})
export default class PostSelect extends ValueMixin {
    // @ts-ignore
    val!: string | string[]
    @Prop({default: "", required: false}) name!: string
    @Prop({default: "", required: false}) label!: string
    @Prop({default: "", required: false}) message!: string
    @Prop({default: null, required: false}) noBorder!: boolean | null
    @Prop({default: null, required: false}) valid!: boolean | null
    @Prop({default: () => [], required: false}) options!:
        | string[]
        | null

    showMenu = false

    setMenu(value?: boolean): void {
        this.showMenu = value === undefined ? !this.showMenu : value
    }

    close(): void {
        this.showMenu = false
    }

    open(): void {
        this.showMenu = true
    }
}
