





































// https://www.experience-hub.ch/document/2385#/komponenten-1/eingabefelder

import { Component, Prop } from "vue-property-decorator"
import ValueMixin from "@/components/valueMixin"

@Component
export default class PostInput extends ValueMixin {
    // @ts-ignore
    val!: string
    @Prop({ default: "", required: false, type: String }) message!: string
    @Prop({ default: "", required: false, type: String }) errorMessage!: string
    @Prop({ default: "text", required: false, type: String }) type!: string
    @Prop({ default: null, required: false, type: Boolean }) valid!: boolean

    blured(): void {
        this.$emit("blur")
    }
}
