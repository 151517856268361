import {Vue, Component, Prop} from "vue-property-decorator"

// This mixin creates a value "val" which replaces the value prop. When val is set, it throws an input event to the parent component.
// That way, val can be used to access and write value as a normal javascript variable, without having to think about events and props.
// If value isn't defined, a fallback variable is provided. That way, developers can easysily write components with an optional value prop.
// For example, input components can be written and controlled from the "outside" bit can also exist and work stand-alone.
@Component
export default class ValueClass extends Vue {
    // value is set to unknown. This is overwritten in other components which trows a typescript error.
    // That error is ignored as it's the intended functionality of the mixin.
    @Prop({required: false}) private readonly value!: unknown
    // used if value prop isn't defined
    fallbackValue: unknown = null

    get val(): unknown {
        if (this.value !== undefined) {
            return this.value
        } else {
            return this.fallbackValue
        }
    }

    set val(newVal: unknown) {
        // setting val throws an input event to parent component
        this.$emit("input", newVal)
        if (this.value === undefined) {
            this.fallbackValue = newVal
        }
    }
}
