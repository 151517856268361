import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import Home from "@/views/Home.vue"
import ServiceDetails from "@/views/ServiceDetails.vue"
import Contact from "@/views/Contact.vue"
import Login from "@/views/Login/Login.vue"
import forgotPassword from "@/views/Login/forgotPassword.vue"
import loginRegister from "@/views/Login/loginRegister.vue"
import registerSuccess from "@/views/Login/registerSuccess.vue"
import passwordSuccess from "@/views/Login/passwordSuccess.vue"
import emailSuccess from "@/views/Login/emailSuccess.vue"
import newPassword from "@/views/Login/newPassword.vue"
import Documents from "@/views/Restricted/Documents.vue"
import forgotPasswordSuccess from "@/views/Login/forgotPasswordSuccess.vue"
import DocumentDetails from "@/views/Restricted/DocumentDetails.vue"
import store from "@/store/index"
import i18n from "@/i18n"
import axios, { AxiosResponse } from "axios"
import { OpenAPI, User } from "@/api"
import AdminInterface from "@/views/Login/AdminInterface.vue"
import AdminPanel from "@/views/Login/AdminPanel.vue"
import contactSuccess from "@/views/Contact/contactSuccess.vue"
import contactFail from "@/views/Contact/contactFail.vue"
import contactError from "@/views/Login/contactError.vue"
import pageNotFound from "@/views/PageNotFound.vue"
import Confirm from "@/views/Confirm.vue"
import { DataLayerObject, getDefaultValues } from "@/track"
import { serviceResponse } from "@/types/meshInterfaces"
import de from "@/locales/de.json"

const deRouteNames = de.meta

Vue.use(VueRouter)

const germanOnlyRedirect: RouteConfig["beforeEnter"] = (to, _from, next) => {
    if (to.params.lang !== "de") {
        next({ path: to.path.replace(`/${to.params.lang}/`, "/de/"), params: { ...to.params, lang: "de" } })
    } else next()
}

const langLessRoutes: Array<RouteConfig> = [
    {
        path: "/admin",
        name: "admininterface",
        component: AdminInterface,
        meta: { requiresLogin: true, requiresAdmin: true, noIndex: true },
    },
    {
        path: "/admin/:id/panel",
        name: "adminpanel",
        component: AdminPanel,
        meta: { requiresLogin: true, requiresAdmin: true, noIndex: true },
    },
    {
        path: "/confirm",
        name: "Confirm",
        // @ts-ignore
        component: { render(c) { return c("router-view") } },
        children: [{
            path: ":token",
            name: "ConfirmToken",
            component: Confirm,
            meta: { noIndex: true },
        }],
        meta: { noIndex: true },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: { attemptLogin: true, noIndex: true }
    },
    {
        path: "/login/password",
        name: "forgotpassword",
        component: forgotPassword,
        meta: { noIndex: true },
    },
    {
        path: "/login/passwordsuccess",
        name: "forgotpasswordsuccess",
        component: forgotPasswordSuccess,
        meta: { noIndex: true },
    },
    {
        path: "/login/register",
        name: "loginregister",
        component: loginRegister,
        meta: { noIndex: true },
    },
    {
        path: "/login/regsuccess",
        name: "registersuccess",
        component: registerSuccess,
        meta: { noIndex: true },
    },
    {
        path: "/login/passsuccess",
        name: "passwordsuccess",
        component: passwordSuccess,
        meta: { noIndex: true },
    },
    {
        path: "/login/mailsuccess",
        name: "emailsuccess",
        component: emailSuccess,
        meta: { noIndex: true },
    },
    {
        path: "/resetpassword",
        name: "newpassword",
        // @ts-ignore
        component: { render(c) { return c("router-view") } },
        children: [{
            path: ":token",
            name: "newpasswordToken",
            component: newPassword,
            meta: { noIndex: true },
        }],
        meta: { noIndex: true },
    },
    {
        path: "/contact/error",
        name: "contacterror",
        component: contactError,
        meta: { noIndex: true },
    },
    {
        path: "/contact",
        name: "contact",
        component: Contact,
    },
    {
        path: "/de/kontakt",
        name: "contact",
        component: Contact,
    },
    {
        path: "/it/contatto",
        name: "contact",
        component: Contact,
    },
    {
        path: "/contact/success",
        name: "contactsuccess",
        component: contactSuccess,
        meta: { noIndex: true },
    },
    {
        path: "/contact/fail",
        name: "contactfail",
        component: contactFail,
        meta: { noIndex: true },
    },
    {
        path: "/restricted/documents",
        name: "Documents",
        component: Documents,
        children: [{
            path: ":service/:namedUrl?",
            name: "documentDetails",
            component: DocumentDetails,
            meta: { noIndex: true },
            beforeEnter: germanOnlyRedirect,
        }],
        meta: { requiresLogin: true, noIndex: true },
        beforeEnter: germanOnlyRedirect,
    },
    {
        path: "/services",
        name: "home",
        component: Home,
        children: [{
            path: ":service/:namedUrl?",
            name: "serviceDetails",
            component: ServiceDetails,
            beforeEnter: (to, _from, next) => {
                if (to.params.lang !== "de" && !to.params.service.toLocaleLowerCase().endsWith(to.params.lang)) {
                    const servicePath = to.params.service.replace(/-[A-Z]+ */, "") + `-${to.params.lang.toUpperCase()}`
                    next(to.path.replace(to.params.service, servicePath))
                } else if (to.params.lang === "de" && /-[A-Z]+/.test(to.params.service)) {
                    const servicePath = to.params.service.replace(/-[A-Z]+ */, "")
                    next(to.path.replace(to.params.service, servicePath))
                } else {
                    next()
                }
            }
        }],
    }
]

let routes: Array<RouteConfig> = [
    {
        path: "/pagenotfound",
        component: pageNotFound,
        meta: { noIndex: true },
    },
    {
        path: "/:lang",
        name: "home",
        component: Home
    },
    {
        path: "*",
        component: pageNotFound,
        meta: { noIndex: true },
    },
]

routes = routes.concat(langLessRoutes)
routes = routes.concat(langLessRoutes.map(route => ({ ...route, path: "/:lang" + route.path })))

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (to.name === "contact") {
            return {
                selector: "#app",
                behavior: "smooth",
            }
        }
    },
})

router.beforeEach((to, from, next) => {
    const urlQuery = { ...Object.fromEntries(new URLSearchParams(location.search)), ...from.query }
    if (to.path === "/pagenotfound") {
        next()
    } else if (to.params.lang && !["de", "fr", "it", "en"].includes(to.params.lang)) {
        if (to.path !== "/pagenotfound") {
            next("/pagenotfound")
        } else {
            next()
        }
    } else if (to.matched[0].path === "*" && to.path !== "/") {
        next("/pagenotfound")
    } else if (!to.params.lang) {
        const storageLang = localStorage.getItem("lang")

        if (storageLang) {
            if (i18n.locale !== storageLang) i18n.locale = storageLang
            const routeArr = to.path.split("/")
            routeArr.splice(0, 0, i18n.locale)
            next({ path: (routeArr.filter(i => !!i) || []).join("/") || "/", query: urlQuery })
        } else {
            const browserLang = window.navigator.language?.split("_")[0]
            if (["de", "fr", "it", "en"].includes(browserLang.toLocaleLowerCase())) {
                if (i18n.locale !== storageLang) { i18n.locale = browserLang.toLocaleLowerCase() }
            } else {
                if (i18n.locale !== storageLang) i18n.locale = "de"
            }
            const routeArr = to.path.split("/")
            routeArr.splice(0, 0, i18n.locale)
            next({ path: (routeArr.filter(i => !!i) || []).join("/") || "/", query: urlQuery })
        }
    } else {
        i18n.locale = to.params.lang

        if (
            to.matched.some(el => el.meta.requiresLogin)
            || to.matched.some(el => el.meta.attemptLogin)
        ) {
            if (store.state.user) {
                if (to.matched.some(el => el.meta.requiresAdmin) && store.state.user?.role !== "ADMIN") {
                    next(`/${i18n.locale}/login`)
                } else {
                    next()
                }
            } else {
                // if (document.cookie.includes("auth.token")) {
                // @ts-ignore
                axios.post(`${OpenAPI.BASE}/user/login`, null, {
                    headers: {
                        // "X-AUTH-TOKEN": Vue.$cookies.get("auth.token"),
                        "Content-Type": "application/json",
                    }
                })
                    .then((res: AxiosResponse<User>) => {
                        store.commit("setUser", res.data)
                        if (to.matched.some(el => el.meta.requiresAdmin)) {
                            if (res.data.role === "ADMIN") {
                                next()
                            } else {
                                Vue.$cookies.remove("auth.token")
                                Vue.$cookies.remove("meth.token")
                                store.commit("setUser", null)
                                axios.post(`${OpenAPI.BASE}/user/logout`, null)
                                    .then(() => next(`/${i18n.locale}/login`))
                            }
                        } else {
                            next()
                        }
                    })
                    .catch(() => {
                        if (to.matched.some(el => el.meta.requiresLogin)) {
                            next(`/${i18n.locale}/login`)
                        } else {
                            next()
                        }
                    })
            }
        } else {
            next()
        }
    }
})

router.afterEach((to, from) => {
    const _hsq = window._hsq = window._hsq || [];

    if (from.path !== to.path) {
        if (to.name === "documentDetails" || to.name === "serviceDetails") {
            axios.post(
                `${Vue.prototype.$configGenticsApi}/digitalhealth/graphql`,
                { query: "{node(uuid: \"114c8fe880664f328312996a86d5521a\",lang:\"de\"){children(filter:{schema:{is:healthService}}){elements{...on healthService{fields{id name}}}}}}" },
            ).then((res: AxiosResponse<serviceResponse>) => {
                window.pageName = res.data.data.node.children.elements.find(({ fields }) => fields.id === to.params.service.replace("-EN", "").replace("-IT", "").replace("-FR", ""))?.fields.name || "route name error",
                window.dataLayer.push({
                    ...getDefaultValues(),
                    event: "page_change",
                    virtual_pageview: "true",
                } as DataLayerObject)

                _hsq.push(['setPath', window.location.pathname])
                _hsq.push(['trackPageView'])
            })
        } else {
            setTimeout(() => {
                _hsq.push(['setPath', window.location.pathname])
                _hsq.push(['trackPageView'])

                // @ts-ignore
                window.pageName = (to.name && deRouteNames[to.name]) || "route name error"
                window.dataLayer.push({
                    ...getDefaultValues(),
                    event: "page_change",
                    virtual_pageview: "true"
                } as DataLayerObject)
            }, 1000)
        }
    }
})

export default router
