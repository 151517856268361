
























































import {Component, Vue, Prop} from "vue-property-decorator"
import card from "@/components/post/Card.vue"
import pill from "@/components/Pill.vue"
import postButton from "@/components/post/Button.vue"
import Ad from "@/types/ad"

@Component({components: {card, pill, postButton}})
export default class AdCard extends Vue {
    @Prop({required: true}) value!: Ad
    @Prop({required: false}) img:
        | { url: string; description: string }
        | undefined
    $configGenticsApi!: string
    $mq!: string
}
