





























import {Component, Vue, Prop} from "vue-property-decorator"

@Component
export default class Pill extends Vue {
    @Prop({default: "", required: false}) color!: string
    @Prop({default: false, required: false}) border!: boolean
}
