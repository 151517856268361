




















































// https://www.experience-hub.ch/document/2385#/komponenten-1/dialoge

import { Component, Prop, Watch } from "vue-property-decorator"
import ValueMixin from "@/components/valueMixin"
import postButton from "@/components/post/Button.vue"

@Component({ components: { postButton } })
export default class Dialog extends ValueMixin {
    @Prop({ default: "title missing", required: false }) title:
        | string
        | undefined

    @Prop({ required: false }) contentKey!: string | number | boolean
    @Prop({ required: false, type: Boolean, default: false }) dark!: boolean

    $refs!: { dialog: HTMLDivElement, dialogContent: HTMLDivElement }

    isMobileSafari = false
    show = false

    mounted(): void {
        const ua = window.navigator.userAgent
        this.isMobileSafari =
            (ua.includes("iPad") || ua.includes("iPhone")) &&
            ua.includes("WebKit")

        this.setMaxHeight()

        window.addEventListener("resize", this.setMaxHeight, { passive: true })
    }

    beforeDestroy(): void {
        window.removeEventListener("resize", this.setMaxHeight)
    }

    @Watch("contentKey") onContentChange(): void {
        this.show = false
        this.setMaxHeight()
    }

    @Watch("val") onValueChange(): void {
        this.show = false
        this.setMaxHeight()
    }

    // set max height in edge cases
    setMaxHeight(): void {
        setTimeout(() => {
            if (this.val && this.$refs.dialogContent) {
                const computedStyle = getComputedStyle(this.$refs.dialog)
                let dialogHeight = this.$refs.dialog.clientHeight
                dialogHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)
                this.$refs.dialogContent.style.maxHeight = `${dialogHeight}px`
                this.show = true
            }
        }, 200)
    }
}
